import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../components/app/App';
import {Route, Routes} from 'react-router-dom';
import AuthProtected from './AuthProtected';
import {isApiError} from '../services/isApiError';
import Layout from './Layout';
import {Home} from '../pages/home/Home';
import {List} from '../pages/list/List';
import {Carriers} from '../pages/carrier/Carriers';
import CargoDrafts from '../cargoDrafts/page/CargoDrafts';
import LoginPage from '../authorization/page/LoginPage';
import Deals from '../deals/page/Deals';
import {ICargo, ICarrier, Logist, LogistsNames} from '../types';
import ReportPage from '../report/pages/ReportPage';

const Index = () => {
	const {isAuth, setIsAuth} = useContext(AuthContext);
	const [map, setMap] = useState<google.maps.Map | null>(null);

	const [cars, setCars] = useState<ICarrier[]>([]);
	const [cargos, setCargos] = useState<ICargo[]>([]);

	const [logists, setLogists] = useState<Logist[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				//Currently this query is important at this component for CargoDrafts, as it runs once anyway on any page.
				//At the same time, it checks the validity of the token and redirects to the login page if token invalid.
				//If this request is moved, this behavior must be corrected
				const response = await fetch('https://mapbot.testprojects.net/api/v1/unique-logists',

					{
						headers: {
							'Accept': 'application/json',
							'Content-type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('token')
						}
					});
				if (response.status === 401) {
					setIsAuth(false);
					localStorage.removeItem('token');
				} else {
					const data: Logist[] = await response.json();
					setLogists(data);
					const transformedData: LogistsNames = data.reduce((acc: LogistsNames, user) => {
						acc[user.login] = user.name;
						return acc;
					}, {});
					localStorage.setItem('logists', JSON.stringify(transformedData));
				}
			} catch (error) {
				if(isApiError(error) && error.response.status === 401){
					setIsAuth(false);
				}
			}
		};
		if(isAuth){
			fetchData();
		}
	}, [isAuth]);

	return (
		<Routes>
			<Route element={<AuthProtected/>}>
				<Route element={<Layout/>}>
					<Route path="/" element={
						<Home
							map={map}
							setMap={setMap}
							cars={cars}
							setCars={setCars}
							cargos={cargos}
							setCargos={setCargos}
							logists={logists}
						/>
					}/>
					<Route path="list" element={
						<List
							map={map}
							setMap={setMap}
							cars={cars}
							setCars={setCars}
							cargos={cargos}
							setCargos={setCargos}
							logists={logists}/>
					}/>
					<Route path="carrier" element={
						<Carriers/>
					}/>
					<Route path="cargo-drafts" element={<CargoDrafts logists={logists}/>}/>
					<Route path="deals" element={<Deals logists={logists}/>}/>
					<Route path="report" element={<ReportPage logistList={logists}/>}/>
				</Route>
			</Route>
			<Route path='/login' element={<LoginPage/>}/>
		</Routes>
	);
};

export default Index;
