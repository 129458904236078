import React, {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import useAxios from '../../hook/useAxios';
import {List as ListType} from '../types/response';
import {Deal, DealsReq} from '../types';
import {getDeals} from '../api/api';
import DealsList from '../components/DealsList';
import DisplayDeal from '../components/DisplayDeal';
import dayjs from 'dayjs';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {Logist} from '../../types';

interface Props {
	logists: Logist[]
}

const Deals: React.FC<Props> = ({logists}) => {
	const now = Date.now();
	const [currentDeal, setCurrentDeal] = useState<Deal | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [logistFilter, setLogistFilter] = useState('all');
	const [fromFilter, setFromFilter] = useState< dayjs.Dayjs>(dayjs(new Date(now - 604800000)));
	const [toFilter, setToFilter] = useState<dayjs.Dayjs>(dayjs(new Date()));
	const apiResponse = useAxios<ListType<Deal>, DealsReq>(getDeals, null);
	const controller = new AbortController();
	const handleChangeFilter = () => {
		const req: DealsReq['req'] = {page: 1};
		setCurrentPage(1);
		if(apiResponse.isLoading){
			controller.abort();
		}
		if(logistFilter){
			req.filters = {logist: logistFilter === 'all' ? '' : logistFilter};
		}
		if(fromFilter){
			req.filters = {...req.filters, created_at: {...req.filters?.created_at, from: dateToDayMonthYearString(new Date(fromFilter.toString()))}};
		}
		if(toFilter){
			req.filters = {...req.filters, created_at: {...req.filters?.created_at, to:  dateToDayMonthYearString(new Date(toFilter.toString()))}};
		}
		apiResponse.fetchData({req, controller: controller});
	};

	const handlePagination = () => {
		if(currentPage !== 1){
			if(apiResponse.isLoading) {
				controller.abort();
			}
			const req: DealsReq['req'] = {page: currentPage};

			if(logistFilter){
				req.filters = {logist: logistFilter === 'all' ? '' : logistFilter};
			}
			if(fromFilter){
				req.filters = {...req.filters, created_at: {...req.filters?.created_at, from: dateToDayMonthYearString(new Date(fromFilter.toString()))}};
			}
			if(toFilter){
				req.filters = {...req.filters, created_at: {...req.filters?.created_at, to:  dateToDayMonthYearString(new Date(toFilter.toString()))}};
			}
			apiResponse.fetchData({req, controller: controller});
		}
	};

	useEffect(() => {
		handlePagination();
	}, [currentPage]);

	useEffect(() => {
		handleChangeFilter();
	}, [fromFilter, toFilter, logistFilter]);

	return (
		<Container sx={{width: '100%', display: 'flex'}}>
			<DisplayDeal currentDeal={currentDeal}/>
			<DealsList
				logists={logists}
				currentDeal={currentDeal} 
				setCurrentDeal={setCurrentDeal} 
				currentPage={currentPage} 
				setCurrentPage={setCurrentPage}  
				apiResponse={apiResponse}
				logistFilter={logistFilter}
				setLogistFilter={setLogistFilter}
				fromFilter={fromFilter}
				setFromFilter={setFromFilter}
				toFilter={toFilter}
				setToFilter={setToFilter}
			/>
		</Container>
	);
};

export default Deals;