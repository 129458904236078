import React from 'react';
import {OverlayComponent} from './OverlayComponent';
import {dateToDayMonthYearString} from '../services/TimeService';
import {Box, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {ICargo, ICarrier} from '../types';
import {getCurrencyAndType} from '../services/getCurrencyAndType';
import {getLogistName} from '../services/getLogistName';

interface PopupCombineProps {
  isOpen: boolean;
  onClose: () => void;
  carrier: ICarrier;
  cargo: ICargo;
}

export const PopupCombine: React.FC<PopupCombineProps> = ({
	isOpen,
	onClose,
	cargo,
	carrier,
}) => {

	const {enqueueSnackbar} = useSnackbar();
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	const handleSubmit = () => {
		const obj = {
			cargo_id: cargo.id,
			carrier_id: carrier.id,
		};
		if (cargo.id && carrier.id) {
			fetch('https://mapbot.testprojects.net/api/v1/deal', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('token')
				},
				body: JSON.stringify(obj)
			})
				.then(response => {
					if (response.ok) {
						enqueueSnackbar('Угоду створено', {variant: 'success'});
						onClose();
						return response.json();
					} else {
						throw Error('Сталася помилка');
					}
				})
				.catch(error => {
					enqueueSnackbar('Сталася помилка', {variant: 'error'});
					console.error(error);
				});
		}
	};
	const displayValue = (label: string, value: string | null) => (
		<p>{label}: <b>{value ?? '---'}</b></p>
	);

	return (
		<OverlayComponent onClose={onClose} isOpen={isOpen}>
			<div className="popup-body">
				<Box
					sx={{
						position: 'relative',
						maxHeight: 'calc(100vh - 48px)',
						height: '100%',
						overflow: 'auto'
					}}
				>
					<div>
						<h3>Перевізник</h3>
						{displayValue('CRM ID', carrier.crm_id)}
						{displayValue('Логіст', getLogistName(logistsList, carrier.logist ?? '---'))}
						{displayValue('Адреса', carrier.address)}
						{displayValue('Довантаження', carrier.additional_load ? 'так' : 'ні')}
						{displayValue('Місце призначення 1', carrier.destination_location1)}
						{carrier.destination_location2 && displayValue('Місце призначення 2', carrier.destination_location2)}
						{carrier.destination_location3 && displayValue('Місце призначення 3', carrier.destination_location3)}
						{displayValue('ADR', carrier.adr === 1 ? 'Так' : 'Ні')}
						{displayValue('Доступний з', dateToDayMonthYearString(new Date(carrier.available_from)))}
						{displayValue('Доступний до', dateToDayMonthYearString(new Date(carrier.available_until)))}
						{displayValue('Створено', dateToDayMonthYearString(new Date(carrier.created_at)))}
						{displayValue('Тип причепа', carrier.trailer_type)}
						{displayValue('Вага', carrier.weight + 'т')}
						{displayValue('Орієнтовна сума', carrier.estimated_amount ? carrier.estimated_amount.toString() + ' ' + getCurrencyAndType(carrier.currency, carrier.is_price_total) : '---')}
						{displayValue('Коментар', carrier.comment)}
						{carrier.private_comment && displayValue('Приватний коментар', carrier.private_comment)}
					</div>
					<div>
						<h3>Вантаж</h3>
						{displayValue('CRM ID', cargo.crm_id)}
						{displayValue('Логіст', getLogistName(logistsList, cargo.logist ?? '---'))}
						{displayValue('Адреса з', cargo.address_from)}
						{displayValue('Адреса до', cargo.address_to)}
						{displayValue('Доступний з', dateToDayMonthYearString(new Date(cargo.available_from)))}
						{displayValue('Доступний до', dateToDayMonthYearString(new Date(cargo.available_until)))}
						{displayValue('Створено', dateToDayMonthYearString(new Date(cargo.created_at)))}
						{displayValue('Тип причепа', cargo.trailer_type)}
						{displayValue('Вага', cargo.weight_volume + 'т')}
						{displayValue('ADR', cargo.adr === 1 ? 'Так' : 'Ні')}
						{displayValue('Кількість авто', cargo.vehicle_count ? cargo.vehicle_count.toString() : '---')}
						{displayValue('Пройшов ветеренара', cargo.veterinary === null ? '---' : cargo.veterinary ? 'Так' : 'Ні')}
						{displayValue('Орієнтовна вартість', cargo.estimated_cost ? cargo.estimated_cost + ' ' + getCurrencyAndType(cargo.currency, cargo.is_price_total) : '---' )}
						{displayValue('Коментар', cargo.comment)}
					</div>
				</Box>
				<div className="button-block">
					<Button onClick={handleSubmit}>Підтвердити</Button>
				</div>
			</div>
		</OverlayComponent>
	);
};