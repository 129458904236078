import React, {useState} from 'react';
import {useJsApiLoader} from '@react-google-maps/api';

export const useGoogleMapApi = (map: google.maps.Map | null, setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>) => {
	const [libraries] = useState<('places' | 'drawing' | 'geometry' | 'visualization')[]>(['places']);

	const API_KEY = process.env.REACT_APP_API_KEY;

	const {isLoaded, loadError} = useJsApiLoader({
		googleMapsApiKey: `${API_KEY}`,
		id: 'google-map-component',
		libraries,
		language: 'uk',
		region: 'ua'
	});

	const onLoad = React.useCallback(
		function onLoad(mapInstance: google.maps.Map): void {
			setMap(mapInstance);
		}, []);

	const onUnmount = React.useCallback(function callback(): void {
		setMap(null);
	}, []);

	return {isLoaded, loadError, onLoad, onUnmount};
};