import React, {useState} from 'react';
import {useGoogleMapApi} from '../../hook/useGoogleMapApi';
import {GoogleMap, MarkerClusterer} from '@react-google-maps/api';
import {Box, Switch} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import {ICargo, ICarrier} from '../../types';
import CargoMarkerList from './CargoMarkerList';
import CarrierMarkerList from './CarrierMarkerList';

declare interface IPosition {
	lat: number;
	lng: number;
}
interface Props {
	map: google.maps.Map | null
	setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>
	carrier: ICarrier[]
	cargos: ICargo[]
	isGroupMode: boolean,
	setIsGroupMode: (value: boolean) => void
}

const mapOptions = {
	streetViewControl: false,
	mapTypeControl: false,
	fullscreenControl: false,
};

const MapWithClusters: React.FC<Props> = ({map, setMap, carrier, cargos, setIsGroupMode, isGroupMode}) => {
	const [center] = useState<IPosition>({lat: 48.7489884, lng: 19.1401256});
	const {isLoaded, loadError, onLoad, onUnmount} = useGoogleMapApi(map, setMap);
	const [marker, setMarker] = useState<string | null>(null);

	const containerStyle = {
		height: '100%',
		width: '100vw'
	};

	if (loadError) {
		return <p>Помилка завантаженя мапи, повторіть спробу пізніше</p>;
	}


	return (<>{isLoaded &&
		<GoogleMap
			mapContainerClassName="google-map"
			mapContainerStyle={containerStyle}
			center={center}
			zoom={5}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={mapOptions}
			onClick={() => setMarker(null)}>
			<Box sx={{
				position: 'absolute',
				top: 90,
				left: 12,
				zIndex: 100,
				backgroundColor: 'white',
				borderRadius: '12px',
				padding: '6px'
			}}>
				<FormControlLabel
					value={isGroupMode}
					onChange={(event, checked) => setIsGroupMode(checked)}
					control={<Switch checked={isGroupMode}/>}
					label="Групування"
				/>
			</Box>
			<MarkerClusterer styles={[{
				url: require('../../assets/icons/m1.png'),
				textSize: 14,
				width: 68,
				height: 68,
				//color: #00FF00,
			}]}>
				{(clusterer) => (
					<CargoMarkerList cargos={cargos} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
				)}
			</MarkerClusterer>
			<MarkerClusterer styles={[{
				url: require('../../assets/icons/m2.png'),
				width: 68,
				height: 68,
				textSize: 14,
				//color: #00FF00,
			}]}>
				{(clusterer) => (
					<CarrierMarkerList carrier={carrier} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
				)}
			</MarkerClusterer>
		</GoogleMap>
	}</>);
};

export default MapWithClusters;