import React, {useState} from 'react';
import {IconButton, TextField, Typography} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useSnackbar} from 'notistack';
import {ICargo, LocalStorage, Logist} from '../../types';
import {getLogistName} from '../../services/getLogistName';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {getCurrencyAndType} from '../../services/getCurrencyAndType';
import EditIcon from '@mui/icons-material/Edit';
import EditCargo from '../EditCargo';

interface CargoListProps {
  list: ICargo[];
  setList: React.Dispatch<React.SetStateAction<ICargo[]>>;
  setCargoId: React.Dispatch<React.SetStateAction<string>>;
  logists: Logist[]
}

export const CargoList: React.FC<CargoListProps> = ({
	list,
	setList,
	setCargoId,
	logists
}) => {
	const [editCargo, setEditCargo] = useState<null | ICargo>(null);
	const [term, setTerm] = useState<string>('');
	const {enqueueSnackbar} = useSnackbar();
	const handleClickCargo = (id: string) => {
		setCargoId(id);
	};

	const search = (data: ICargo[], value: string) => {
		if (value.length === 0) {
			return data;
		} else return data.filter(item => item.crm_id == value);
	};
	const login = localStorage.getItem(LocalStorage.login);
	const visibleData = search(list, term);
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	const displayValue = (label: string, value: string | null) => (
		<span style={{marginRight: '6px'}}><b>{label}:</b> {value ?? '---'}.</span>
	);
	const deleteItem = async (id: string) => {
		await fetch(`https://mapbot.testprojects.net/api/v1/cargos/${id}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem('token')
			}
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					enqueueSnackbar('Видалено', {variant: 'success'});
					setList(item => item.filter(cargo => cargo.id != id));
					return response.json();
				} else if (response.status >= 400) {
					enqueueSnackbar('Cталася помилка', {variant: 'error'});
					throw Error(response.statusText);
				}
			})
			.then(data => console.log(data))
			.catch(error => console.error(error));
	};

	return (
		<div className="cargo-list-block">
			<h3>Вантажі</h3>
			<TextField
				variant="outlined"
				fullWidth
				value={term}
				onChange={event => setTerm(event.target.value)}
				placeholder="Пошук по ID"/>
			<ul className="cargo-list">
				{visibleData.map((cargo) => {
					return <li key={cargo.id}
						onClick={() => handleClickCargo(cargo.id)}>
						<div className="cargo-radio-button">
							<input
								type="radio"
								name="cargorId"
								value={cargo.id}
							/>
						</div>
						<div>
							{displayValue('CRM ID', cargo.crm_id)}
							{displayValue('Логіст', getLogistName(logistsList, cargo.logist ?? '---'))}
							{displayValue('Адреса з', cargo.address_from)}
							{displayValue('Адреса до', cargo.address_to)}
							{displayValue('Доступний з', dateToDayMonthYearString(new Date(cargo.available_from)))}
							{displayValue('Доступний до', dateToDayMonthYearString(new Date(cargo.available_until)))}
							{displayValue('Створено', dateToDayMonthYearString(new Date(cargo.created_at)))}
							{displayValue('Тип причепа', cargo.trailer_type)}
							{displayValue('Вага', cargo.weight_volume ? cargo.weight_volume + 'т' : '---')}
							{displayValue('ADR', cargo.adr === 1 ? 'Так' : 'Ні')}
							{displayValue('Кількість авто', cargo.vehicle_count ? cargo.vehicle_count.toString() : '---')}
							{displayValue('Пройшов ветеренара', cargo.veterinary === null ? '---' : cargo.veterinary ? 'Так' : 'Ні')}
							{displayValue('Орієнтовна вартість', cargo.estimated_cost ? cargo.estimated_cost + ' ' + getCurrencyAndType(cargo.currency, cargo.is_price_total) : '---' )}
							{displayValue('Коментар', cargo.comment)}
						</div>
						{login === cargo.logist &&
							<IconButton onClick={() => deleteItem(cargo.id)}>
								<DeleteForeverIcon/>
							</IconButton>
						}
						{login === cargo.logist &&
							<IconButton onClick={() => setEditCargo(cargo)}>
								<EditIcon/>
							</IconButton>
						}
					</li>;
				})}
				{list.length === 0 && <Typography sx={{paddingTop: '50px'}} color="lightgrey">Дані відсутні, спробуйте змінити параметри фільтру</Typography>}
			</ul>
			{editCargo && <EditCargo logists={logists} currentCargo={editCargo} isOpen={!!editCargo} handleClose={() => setEditCargo(null)}/>}
		</div>
	);
};
