import React from 'react';
import {Container, Typography} from '@mui/material';
import Loader from '../../cargoDrafts/ui/Loader';
import {Deal, } from '../types';
import Row from '../ui/Row';
import {getLogistName} from '../../services/getLogistName';
import {getCurrencyAndType} from '../../services/getCurrencyAndType';

interface Props {
	currentDeal: Deal | null
}

const DisplayDeal: React.FC<Props> = ({currentDeal}) => {
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};

	return (currentDeal ?
		<Container sx={{pt: '28px', display: 'flex'}}>
			<Container sx={{width: '50%'}}>
				<Typography variant='h4' gutterBottom>
					Вантаж
				</Typography>
				<Row label='CRM ID:' value={currentDeal.cargo.crm_id}/>
				<Row label='Адреса відправлення:' value={currentDeal.cargo.address_from}/>
				<Row label='Адреса доставки:' value={currentDeal.cargo.address_to}/>
				<Row label='Відповідальна особа:' value={currentDeal.cargo.responsible_person}/>
				<Row label='Тип причепа:' value={currentDeal.cargo.trailer_type}/>
				<Row label='Кількість транспорту:' value={currentDeal.cargo.vehicle_count}/>
				<Row label='Коментар:' value={currentDeal.cargo.comment}/>
				<Row label='Ціна:' value={currentDeal.cargo.estimated_cost + ' ' + getCurrencyAndType(currentDeal.cargo.currency, currentDeal.cargo.is_price_total)}/>
				<Row label='Дата створення замовлення:' value={currentDeal.cargo.order_creation_date}/>
				<Row label='Доступно з:' value={currentDeal.cargo.available_from}/>
				<Row label='Доступно по:' value={currentDeal.cargo.available_until}/>
				<Row label='Вага в тонах:' value={currentDeal.cargo.weight_volume}/>
				<Row label='Потрібні ветеринарні документи? :' value={currentDeal.cargo.veterinary === true ? 'Так' : currentDeal.cargo.veterinary === false ? 'Ні' : 'Данні відсутні'}/>
				<Row label='ADR:' value={currentDeal.cargo.adr ? 'Так' : 'Ні'}/>
				<Row label='Логіст:' value={getLogistName(logistsList, currentDeal.cargo.logist ?? '')}/>
			</Container>
			<Container sx={{width: '50%'}}>
				<Typography variant='h4' gutterBottom>
					Перевізник
				</Typography>
				<Row label='ID:' value={currentDeal.carrier.id}/>
				<Row label='Адреса:' value={currentDeal.carrier.address}/>
				<Row label='Пункт призначення:' value={currentDeal.carrier.destination_location1}/>
				<Row label='Пункт призначення 2:' value={currentDeal.carrier.destination_location2}/>
				<Row label='Пункт призначення 3:' value={currentDeal.carrier.destination_location3}/>
				<Row label='Тип причепа:' value={currentDeal.carrier.trailer_type}/>
				<Row label='Коментар:' value={currentDeal.carrier.comment}/>
				<Row label='Довантаження:' value={currentDeal.carrier.additional_load === true ? 'Так' : 'Ні'}/>
				<Row label='Орієнтовна ціна:' value={currentDeal.carrier.estimated_amount + ' ' + getCurrencyAndType(currentDeal.carrier.currency, currentDeal.carrier.is_price_total)}/>
				<Row label='ADR:' value={currentDeal.carrier.adr ? 'Так' : 'Ні'}/>
				<Row label='Логіст:' value={getLogistName(logistsList, currentDeal.carrier.logist ?? '')}/>
				<Row label='Приватний коментар:' value={currentDeal.carrier.private_comment}/>
			</Container>
		</Container> :
		<Loader/>
	);
};

export default DisplayDeal;
