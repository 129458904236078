import React from 'react';
import {ReportRes} from '../types';
import Paper from '@mui/material/Paper';
import {Alert, Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';

interface Props {
	data: ReportRes | undefined
	isSuccess: boolean
}

const ReportDisplay: React.FC<Props> = ({data, isSuccess}) => {
	return (
		<Box>
			{data && isSuccess ? (data.data.length ?
				<TableContainer sx={{flex: 1}} component={Paper}>
					<Table sx={{flex: 1}}>
						<TableHead sx={{flex: 1}}>
							<TableRow>
								<TableCell>Дата</TableCell>
								<TableCell>Логіст</TableCell>
								<TableCell>К-ть перевізників</TableCell>
								<TableCell>К-ть вантажів</TableCell>
								<TableCell>К-ть угод</TableCell>
							</TableRow>
						</TableHead>
						<TableBody sx={{flex: 1}}>
							{data.data.map((item) => (
								<TableRow
									key={item.date + item.logists}
								>
									<TableCell component="th" scope="row">
										{item.date}
									</TableCell>
									<TableCell>
										{item.logists}
									</TableCell>
									<TableCell>
										{item.carriers_count}
									</TableCell>
									<TableCell>
										{item.cargos_count}
									</TableCell>
									<TableCell>
										{item.deals_count}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter sx={{flex: 1}}>
							<TableRow>
								<TableCell>
									Всього
								</TableCell>
								<TableCell/>
								<TableCell>
									{data.totals.carriers_total}
								</TableCell>
								<TableCell>
									{data.totals.cargos_total}
								</TableCell>
								<TableCell>
									{data.totals.deals_total}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer> :
				<Box
					sx={{display: 'flex', justifyContent: 'center'}}
				>
					<Alert severity='info'>За данним запитом данних не знайдено</Alert>
				</Box>) :
				<Box sx={{display: 'flex', justifyContent: 'center'}} ><Alert severity='info'>Оберіть данні для завантаження звіту</Alert></Box>
			}
		</Box>
	);
};

export default ReportDisplay;