export interface ApiErrorResponse {
    status: number;
    data: {
        message?: string;
        errors?: { [k: string]: string[] }
        error?: string
    }
}

export interface AxiosError {
	message: string,
	response: ApiErrorResponse
}

export function isApiError(error: unknown): error is AxiosError {

	return (
		typeof error === 'object' &&
        error != null &&
        'response' in error &&
        'message' in error &&
        typeof (error.response as any).status === 'number' && /* eslint-disable-line */
        typeof (error.response as any).data === 'object' /* eslint-disable-line */
	);
}

