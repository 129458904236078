// dateToTimeString
export const dateToTimeString = (date: Date) => {
	const hour: number = date.getHours();
	const minute: number = date.getMinutes();
	const hourString = hour < 10 ? '0' + hour : hour;
	const minuteString = minute < 10 ? '0' + minute : minute;

	return `${hourString}:${minuteString}`;
};

export const hourToString = (date: Date) => {
	const hour: number = date.getHours();
	const hourString = hour < 10 ? '0' + hour : hour;
	return `${hourString}`;
};

export const minutesToString = (date: Date) => {
	const minute: number = date.getMinutes();
	const minuteString = minute < 10 ? '0' + minute : minute;
	return `${minuteString}`;
};

// dateToDayMonthYearString
export const dateToDayMonthYearString = (date: Date) => {
	const day = ('0' + date.getDate()).slice(-2);
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
};

export const dateToString = (date: Date) => {
	const day = ('0' + date.getDate()).slice(-2);
	return `${day}`;
};

export const monthToString = (date: Date) => {
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	return `${month}`;
};

// timeStringToDate
export const timeStringToDate = (timeString: string) => {
	const [hours, minutes] = timeString.split(':');
	const date = new Date();
	date.setHours(+hours);
	date.setMinutes(+minutes);
    
	return date;
};

// isoStringToDateAndTimeString
export const isoStringToDateAndTimeString = (date: string) => {
	const dateObj = new Date(date);
	return dateObjToDateAndTimeString(dateObj);
};

// dateObjToDateAndTimeString
export const dateObjToDateAndTimeString = (dateObj: Date) => {
	const day = dateObj.getDate();
	const month = dateObj.getMonth() + 1;
	const year = dateObj.getFullYear();
	const dateString = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
	const timeString = dateToTimeString(dateObj);
    
	return { dateString, timeString };
};

export const isoToString = (iso: string) => {
	const date = new Date(iso);
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
	return date.toLocaleString('uk', options);
};

export const getIso = (string: string) => {
	let stringWithT = string.replace(/ /g, 'T');
	stringWithT += 'Z';
	return stringWithT;
};