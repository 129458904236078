import {
	Avatar,
	Box,
	Container,
	CssBaseline,
	FormHelperText,
	TextField,
	Typography
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useAxios from '../../hook/useAxios';
import {LoginReq, LoginRes} from '../types';
import {login as loginConfig} from '../api/api';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import {AuthContext} from '../../components/app/App';
import {useNavigate} from 'react-router-dom';
import {LocalStorage} from '../../types';

const LoginPage = () => {
	const [reqData, setReqData] = useState<null | LoginReq>(null);
	const { setIsAuth, isAuth } = useContext(AuthContext);
	const navigate = useNavigate();

	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');

	const {data, isSuccess, isError, isLoading} = useAxios<LoginRes, LoginReq>(loginConfig, reqData);

	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if(!isLoading && isError){
			setShowError(true);
		}
	}, [isError]);

	useEffect(() => {
		if(showError){
			setShowError(false);
		}
	}, [login, password]);

	useEffect(() => {
		if(isAuth){
			navigate('/');
		}
	}, [isAuth]);

	useEffect(() => {
		if(isSuccess && data){
			localStorage.setItem(LocalStorage.login, login);
			localStorage.setItem(LocalStorage.token, data.access_token);
			setIsAuth(true);
		}
	}, [isSuccess]);
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if(login && password){
			setReqData({
				login,
				password
			});
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Увійти в аккаунт
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<FormControl error={showError} fullWidth>
						<TextField
							error={showError}
							margin="normal"
							required
							fullWidth
							id="login"
							label="Логін"
							name="login"
							autoComplete="login"
							autoFocus
							onChange={(event) => setLogin(event.target.value)}
						/>
						<TextField
							margin="normal"
							error={showError}
							required
							fullWidth
							name="password"
							label="Пароль"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={(event) => setPassword(event.target.value)}
						/>
						{showError && <FormHelperText>Невірний логін або пароль</FormHelperText>}
					</FormControl>
					<LoadingButton
						type="submit"
						fullWidth
						loading={isLoading}
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						<span>Увійти</span>
					</LoadingButton>
				</Box>
			</Box>
		</Container>
	);
};

export default LoginPage;