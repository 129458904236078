import React from 'react';
import {ICargo} from '../../types';
import {Clusterer} from '@react-google-maps/marker-clusterer';
import {InfoWindow, Marker} from '@react-google-maps/api';
import {getLogistName} from '../../services/getLogistName';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {getCurrencyAndType} from '../../services/getCurrencyAndType';

interface Props {
	cargo: ICargo,
	clusterer?: Clusterer,
	marker: string | null,
	setMarker: (value: string | null) => void,
}

const CargoMarker: React.FC<Props> = ({cargo, clusterer, marker, setMarker}) => {
	const box = new URL('/img/png/box_cargo_package_bundle_delivery_icon.png', origin);
	const displayValue = (label: string, value: string|null) => (
		<p>{label}: <b>{value ?? '---'}.</b></p>
	);

	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};

	return (
		<Marker
			position={{lat: +cargo.loading_point_lat, lng: +cargo.loading_point_lon}}
			icon={{
				url: box.toString(),
				scaledSize: new google.maps.Size(34, 34),
			}}
			key={cargo.id}
			clusterer={clusterer}
			animation={google.maps.Animation.DROP}
			onClick={() => {
				setMarker(cargo.id + cargo.type_of_record);
			}}
		>
			{marker === cargo.id + cargo.type_of_record &&
				<InfoWindow
					onCloseClick={() => setMarker(null)}
					position={{lat: +cargo.loading_point_lat, lng: +cargo.loading_point_lon}}
				>
					<div>
						{displayValue('CRM ID', cargo.crm_id)}
						{displayValue('Логіст', getLogistName(logistsList, cargo.logist ?? '---'))}
						{displayValue('Адреса з', cargo.address_from)}
						{displayValue('Адреса до', cargo.address_to)}
						{displayValue('Доступний з', dateToDayMonthYearString(new Date(cargo.available_from)))}
						{displayValue('Доступний до', dateToDayMonthYearString(new Date(cargo.available_until)))}
						{displayValue('Створено', dateToDayMonthYearString(new Date(cargo.created_at)))}
						{displayValue('Тип причепа', cargo.trailer_type)}
						{displayValue('Вага', cargo.weight_volume ? cargo.weight_volume + 'т' : '---')}
						{displayValue('ADR', cargo.adr === 1 ? 'Так' : 'Ні')}
						{displayValue('Кількість авто', cargo.vehicle_count ? cargo.vehicle_count.toString() : '---')}
						{displayValue('Пройшов ветеренара', cargo.veterinary === null ? '---' : cargo.veterinary ? 'Так' : 'Ні')}
						{displayValue('Орієнтовна вартість', cargo.estimated_cost ? cargo.estimated_cost + ' ' + getCurrencyAndType(cargo.currency, cargo.is_price_total) : '---')}
						{displayValue('Коментар', cargo.comment)}
					</div>
				</InfoWindow>
			}
		</Marker>
	);
};

export default CargoMarker;