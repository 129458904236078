import React, {useEffect, useState} from 'react';
import {CarrierList} from '../../components/carrier-list/CarrierList';
import {CargoList} from '../../components/cargo-list/CargoList';
import {Button} from '@mui/material';
import {PopupCombine} from '../../UI/PopupCombine';
import {Filter} from '../../components/filter/Filter';
import {GoogleMap} from '@react-google-maps/api';
import {useGoogleMapApi} from '../../hook/useGoogleMapApi';
import {ICargo, ICarrier, Logist} from '../../types';

interface ListProps {
  map: google.maps.Map | null;
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  cars: ICarrier[];
  setCars: React.Dispatch<React.SetStateAction<ICarrier[]>>;
  cargos: ICargo[];
  setCargos: React.Dispatch<React.SetStateAction<ICargo[]>>;
  logists: Logist[];
}

const containerStyle = {
	width: 0,
	height: 0
};

export const List: React.FC<ListProps> = ({
	cars,
	setCars,
	cargos,
	setCargos,
	map,
	setMap,
	logists
}) => {
	const [carrierId, setCarrierId] = useState<string>('');
	const [cargoId, setCargoId] = useState<string>('');

	const [carrier, setCarrier] = useState<ICarrier>({} as ICarrier);
	const [cargo, setCargo] = useState<ICargo>({} as ICargo);

	const [carrierFetch, setCarrierFetch] = useState<boolean>(false);
	const [cargoFetch, setCargoFetch] = useState<boolean>(false);

	const [showPopup, setShowPopup] = useState<boolean>(false);
	const [center] = useState({lat: 48.7489884, lng: 19.1401256});
	const {isLoaded, onLoad, onUnmount} = useGoogleMapApi(map, setMap);

	const handleCombine = (carrierId: string, cargoId: string) => {
		if (carrierId && cargoId) {
			setCargo({} as ICargo);
			setCarrier({} as ICarrier);
			fetch(`https://mapbot.testprojects.net/api/v1/cargos/${cargoId}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('token')
				},
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					} else if (response.status === 404) {
						throw Error('Помилка 404');
					}
				})
				.then(data => {
					setCargo(data);
					setCargoFetch(true);
				})
				.catch(error => console.error(error));
			fetch(`https://mapbot.testprojects.net/api/v1/carriers/${carrierId}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('token')
				},
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					} else if (response.status === 404) {
						throw Error('Помилка 404');
					}
				})
				.then(data => {
					setCarrier(data);
					setCarrierFetch(true);
				})
				.catch(error => console.error(error));
		}
	};

	useEffect(() => {
		if (cargoFetch && carrierFetch) {
			setShowPopup(true);
			setCargoFetch(false);
			setCarrierFetch(false);
		}
	}, [cargoFetch, carrierFetch]);

	return (
		<>
			<Filter map={map}
				setCars={setCars}
				setCargos={setCargos}
				logists={logists}/>
			<div className="list-wrapper">
				<CarrierList list={cars}
					setList={setCars}
					setCarrierId={setCarrierId}
				/>
				<CargoList
					logists={logists}
					list={cargos}
					setList={setCargos}
					setCargoId={setCargoId}
				/>
			</div>
			<div className="button-block">
				<Button type="button" variant="outlined" onClick={() => handleCombine(carrierId, cargoId)}>Об&apos;єднати</Button>
			</div>
			<PopupCombine isOpen={showPopup}
				onClose={() => setShowPopup(false)}
				carrier={carrier}
				cargo={cargo}
			/>
			{isLoaded && <GoogleMap
				mapContainerClassName="google-map"
				mapContainerStyle={containerStyle}
				center={center}
				onLoad={onLoad}
				onUnmount={onUnmount}/>}
		</>
	);
};