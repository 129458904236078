import React from 'react';
import {ICarrier} from '../../types';
import {Clusterer} from '@react-google-maps/marker-clusterer';
import {InfoWindow, Marker} from '@react-google-maps/api';
import {getLogistName} from '../../services/getLogistName';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {getCurrencyAndType} from '../../services/getCurrencyAndType';

interface Props {
	carrier: ICarrier,
	clusterer?: Clusterer,
	marker: string | null,
	setMarker: (value: string | null) => void,
}

const CarrierMarker: React.FC<Props> = ({carrier, clusterer, marker, setMarker}) => {
	const truck = new URL('/img/png/truck_delivery_shipping_transportation_icon.png', origin);
	const displayValue = (label: string, value: string|null) => (
		<p>{label}: <b>{value ?? '---'}.</b></p>
	);

	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};

	return (
		<Marker
			position={{lat: +carrier.car_location_lat, lng: +carrier.car_location_lon}}
			icon={{
				url: truck.toString(),
				scaledSize: new google.maps.Size(34, 34),
			}}
			clusterer={clusterer}
			animation={google.maps.Animation.DROP}
			onClick={() => setMarker(carrier.id + carrier.type_of_record)}
		>
			{marker === carrier.id + carrier.type_of_record &&
				<InfoWindow
					onCloseClick={() => setMarker(null)}
					position={{lat: +carrier.car_location_lat, lng: +carrier.car_location_lon}}
				>
					<div>
						{displayValue('CRM ID', carrier.crm_id)}
						{displayValue('Логіст', getLogistName(logistsList, carrier.logist ?? '---'))}
						{displayValue('Адреса', carrier.address)}
						{displayValue('Довантаження', carrier.additional_load ? 'так' : 'ні')}
						{displayValue('Місце призначення 1', carrier.destination_location1)}
						{carrier.destination_location2 && displayValue('Місце призначення 2', carrier.destination_location2)}
						{carrier.destination_location3 && displayValue('Місце призначення 3', carrier.destination_location3)}
						{displayValue('ADR', carrier.adr === 1 ? 'Так' : 'Ні')}
						{displayValue('Доступний з', dateToDayMonthYearString(new Date(carrier.available_from)))}
						{displayValue('Доступний до', dateToDayMonthYearString(new Date(carrier.available_until)))}
						{displayValue('Створено', dateToDayMonthYearString(new Date(carrier.created_at)))}
						{displayValue('Тип причепа', carrier.trailer_type)}
						{displayValue('Вага', carrier.weight ? carrier.weight + 'т' : '---')}
						{displayValue('Орієнтовна сума', carrier.estimated_amount ? carrier.estimated_amount.toString() + ' ' + getCurrencyAndType(carrier.currency, carrier.is_price_total) : '---')}
						{displayValue('Коментар', carrier.comment)}
						{carrier.private_comment && displayValue('Приватний коментар', carrier.private_comment)}
					</div>
				</InfoWindow>
			}
		</Marker>
	);
};

export default CarrierMarker;