import {AxiosRequestConfig} from 'axios';
import {EditCargoForm, EditCargoReq, EditCarrierReq, PostCarriersReq, ShowReq} from '../types';

export const showConfig = (request: ShowReq): AxiosRequestConfig => ({
	method: 'POST',
	url: '/show',
	data: JSON.stringify(request.req),
	signal: request.controller.signal
});

export const postCarriers = (request: PostCarriersReq): AxiosRequestConfig => ({
	method: 'POST',
	url: '/carriers',
	data: request
});

export const editCargo = (request: EditCargoReq): AxiosRequestConfig => ({
	method: 'PATCH',
	url: `/cargos/${request.id}`,
	data: request.data
});

export const editCarrier = (request: EditCarrierReq): AxiosRequestConfig => ({
	method: 'PATCH',
	url: `/carriers/${request.id}`,
	data: request.data
});