import React, {useEffect, useRef, useState} from 'react';
import {
	Alert,
	Avatar, Box,
	CircularProgress, IconButton,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText
} from '@mui/material';
import Loader from '../../cargoDrafts/ui/Loader';
import {Deal, DealsReq} from '../types';
import useAxios, {ApiResponse} from '../../hook/useAxios';
import {List as ListType} from '../types/response';
import {isoToString} from '../../services/TimeService';
import {getLogistName} from '../../services/getLogistName';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {SelectLogist} from '../../components/filter/SelectLogist';
import {LocalStorage, Logist} from '../../types';
import DeleteIcon from '@mui/icons-material/Delete';
import {deleteDeal} from '../api/api';
import {useHandleError} from '../../hook/useHandleError';

interface Props {
	currentDeal: Deal | null,
	setCurrentDeal: (value: Deal) => void
	currentPage: number
	setCurrentPage:(value: number) => void
	apiResponse: ApiResponse<ListType<Deal>, DealsReq>
	logistFilter: string,
	setLogistFilter: (value: string) => void
	fromFilter: dayjs.Dayjs
	toFilter: dayjs.Dayjs
	setFromFilter: (value: dayjs.Dayjs) => void
	setToFilter: (value: dayjs.Dayjs) => void
	logists: Logist[]
}

const DealsList: React.FC<Props> = ({
	currentDeal,
	setCurrentDeal,
	currentPage,
	setCurrentPage,
	apiResponse,
	logistFilter,
	setLogistFilter,
	fromFilter,
	setFromFilter,
	toFilter,
	setToFilter,
	logists
}) => {
	const [currentList, setCurrentList] = useState<Deal[]>([]);
	const [deletedId, setDeletedId] = useState<null | number>(null);
	const {isError, isLoading: loading, isSuccess, data} = apiResponse;
	const [isLoading, setIsLoading] = useState(false);
	const containerRef = useRef<HTMLUListElement>(null);
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	const {fetchData: fetchDelete, isLoading: isLoadingDelete, isSuccess: isSuccessDelete, error: errorDelete} = useAxios<void, number|string>(deleteDeal, null);
	useHandleError(errorDelete, 'Помилка видалення');

	useEffect(() => {
		if(isSuccess && !loading && data){
			if(currentPage === 1){
				setCurrentList(data.data);
			} else {
				setCurrentList(prevList => [...prevList, ...data.data]);
			}
			setIsLoading(false);
		}

		if(isSuccess && !currentDeal && data && data.data.length > 0 ){
			setCurrentDeal(data.data[0]);
		}
		if(isError && !loading){
			setIsLoading(false);
		}
	},[isSuccess, loading]);


	useEffect(() => {
		const handleScroll = () => {
			const container = containerRef.current;
			if (container) {
				const { scrollTop, scrollHeight, clientHeight } = container;
				if (data && !isLoading && data.current_page < data.last_page && scrollTop + clientHeight >= scrollHeight - 100) {
					setCurrentPage(data.current_page + 1);
				}
			}
		};
		
		if(loading && !isLoading){
			setIsLoading(true);
		}

		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [data, loading]);

	useEffect(() => {
		if(isSuccessDelete && !isLoadingDelete){
			setCurrentList(prevList => prevList.filter(i => i.id !== deletedId));
		}
		if(!isLoadingDelete && deletedId !== null){
			setDeletedId(null);
		}
	},[isLoadingDelete]);
	
	return (
		<React.Fragment>
			<Box
				sx={{
					width: '100%',
					maxWidth: 360,
				}}
			>
				<Box sx={{pt: '12px'}}>
					<SelectLogist
						mainFilter={false}
						filterList={logists}
						value={logistFilter}
						setValue={setLogistFilter}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
						<Box display='flex' gap='6px'>
							<DatePicker
								label="Пошук з дати"
								value={fromFilter}
								onChange={(value) => {
									if(value !== null){
										setFromFilter(value);
									}
								}}
								maxDate={toFilter}
							/>
							<DatePicker
								label="Пошук по дату"
								value={toFilter}
								onChange={(value) => {
									if(value !== null){
										setToFilter(value);
									}
								}}
								minDate={fromFilter}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
				<List
					sx={{
						flex: 1,
						overflow: 'auto',
						position: 'relative',
						height: '100%',
						maxHeight: 'calc(100vh - 50px - 130px)'
					}}
					ref={containerRef}
				>

					{(isSuccess && !isLoading) ?
						( currentList.length > 0 ?
							currentList.map((item) => (
								<ListItemButton
									key={item.id}
									selected={currentDeal?.id === item.id}
									onClick={() => setCurrentDeal(item)}
								>
									<ListItemAvatar>
										<Avatar>
											{item.id}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={isoToString(item.created_at)}
										secondary={getLogistName(logistsList, item.logist)}
									/>
									{localStorage.getItem(LocalStorage.login) === item.logist &&
										<IconButton
											size='small'
											onClick={() => {
												setDeletedId(item.id);
												fetchDelete(item.id);
											}}
										>
											{deletedId === item.id ? <CircularProgress size={20}/> : <DeleteIcon/>}
										</IconButton>}
								</ListItemButton>
							)) : <Alert sx={{mt: '6px'}} severity="info">За данним запитом не знайдено угод</Alert>
						) : (isError && !isLoading) ? <Alert sx={{mt: '6px'}} severity="error">Помилка завантаження, спробуйте пізніше</Alert> : (currentPage === 1 && <Loader/>)}
					{(isSuccess && isLoading && currentPage !== 1) &&
						<ListItemButton sx={{display: 'flex', width: '100%', height: '72px', justifyContent: 'center', alignItems: 'center'}}>
							<CircularProgress />
						</ListItemButton>
					}
				</List>
			</Box>
		</React.Fragment>
	);
};

export default DealsList;
