import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {RecordType} from '../../types';

interface Props {
  value: 'all' | RecordType;
  setValue: React.Dispatch<React.SetStateAction<'all' | RecordType>>;
}

const typeOfOrder = [{
	title: 'Всі', value: 'all'
}, {
	title: 'Вантаж', value: 'cargo'
}, {
	title: 'Перевізник', value: 'carrier'
},];

export const SelectOrderType: React.FC<Props> = ({
	value,
	setValue,
}) => {

	return (
		<FormControl sx={{m: 1, minWidth: 180}} className="full-width">
			<InputLabel id="select-trailer-label">Тип замовлення</InputLabel>
			<Select
				labelId="select-trailer-label"
				className="bg-white"
				value={value}
				label='Тип замовлення'
				//@ts-ignore
				onChange={(event) => setValue(event.target.value)}
			>
				{typeOfOrder.map((item) => (
					<MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
