import React, {useEffect, useState} from 'react';
import {Alert, Card, CardContent, Container, Typography} from '@mui/material';
import {BotResponse, ErrorLog, ErrorStatus, FixForm, FixParsingErrorReq} from '../types';
import dayjs from 'dayjs';
import Loader from '../ui/Loader';
import useAxios from '../../hook/useAxios';
import {fixParsingError} from '../api/api';
import FixErrorForm from './FixErrorForm';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {useSnackbar} from 'notistack';
import {Logist, TrailerType} from '../../types';
import {useHandleError} from '../../hook/useHandleError';

interface Props {
	currentLog: ErrorLog | null
	refetch: () => void
	clear: () => void
	setCurrentPage: (page: number) => void
	currentPage: number
	logists: Logist[]
}

const currencies = [
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'UAH',
		label: '₴',
	},
	{
		value: 'USD',
		label: '$',
	}
];

const FixErrorLogComponent: React.FC<Props> = ({currentLog, refetch, clear, currentPage, setCurrentPage, logists}) => {
	const currentDay = new Date();
	const [requestData, setRequestData] = useState<FixParsingErrorReq | null>(null);

	const {isSuccess, isLoading, error} = useAxios<void, FixParsingErrorReq>(fixParsingError, requestData);
	const handledErrors = useHandleError(error);
	const {enqueueSnackbar} = useSnackbar();

	const submitForm = (values: FixForm) => {
		if(currentLog && values.available_from && values.available_until){

			const data: FixParsingErrorReq['data'] = {
				logist: values.logist,
				address_from: values.address_from,
				address_to: values.address_to,
				trailer_type: values.trailer_type,
				order_creation_date: values.order_creation_date,
				estimated_cost: values.estimated_cost ? +values.estimated_cost : null,
				available_from: dateToDayMonthYearString(new Date(values.available_from.toString())),
				available_until: dateToDayMonthYearString(new Date(values.available_until.toString())),
				weight_volume: values.weight_volume ? +values.weight_volume : null,
				cargo_type: null,
				veterinary: values.veterinary,
				adr: values.veterinary,
				comment: values.comment ? values.comment : null,
				vehicle_count: values.vehicle_count ? +values.vehicle_count : null,
				currency: (values.currency && values.estimated_cost) ? values.currency : null,
				is_price_total: values.estimated_cost ? (values.is_price_total === 'true' || values.is_price_total === true) : null,
				crm_id: values.crm_id ? +values.crm_id : Date.now(),
				kilkistDniv: +values.kilkistDniv
			};
			setRequestData({errorId: currentLog.id, data});
		}
	};
	const getCustomsClearance = (value: BotResponse['customs_clearance']) => {
		if(typeof value === 'string'){
			return value;
		} else if(value !== null && value !== undefined && typeof value === 'object'){
			return `${value.arrival && value.departure ? value.departure + ',' + value.arrival : value.departure ?? '' + value.arrival ?? ''}`;
		} else {
			return '';
		}
	};
	const getInitialValues = (): FixForm => {
		if(currentLog && typeof currentLog.response_from_bot !== 'string' && currentLog.response_from_bot){
			return ({
				logist: currentLog.payload?.logist ?? '',
				address_from: currentLog.response_from_bot.address_from ?? '',
				address_to: currentLog.response_from_bot.address_to ?? '',
				trailer_type: currentLog.response_from_bot.trailer_type ?? TrailerType.tent,
				order_creation_date: currentLog.created_at,
				estimated_cost: currentLog.response_from_bot.estimated_cost ?? '',
				available_from: dayjs(currentDay),
				available_until: dayjs(currentDay),
				weight_volume: '',
				cargo_type: null,
				veterinary: currentLog.response_from_bot.veterinary,
				adr: currentLog.response_from_bot.adr,
				comment: getCustomsClearance(currentLog.response_from_bot.customs_clearance),
				vehicle_count: '',
				currency: currencies[0].value,
				is_price_total: true,
				crm_id: currentLog.payload?.id ? currentLog.payload?.id.toString() : '',
				kilkistDniv: currentLog.payload?.kilkistDniv ? currentLog.payload?.kilkistDniv.toString() : ''
			});
		} else {
			return ({
				logist: currentLog?.payload?.logist ?? '',
				address_from: '',
				address_to: '',
				trailer_type: TrailerType.tent,
				order_creation_date: currentLog?.created_at ?? '',
				estimated_cost: '',
				available_from: dayjs(currentDay),
				available_until: dayjs(currentDay),
				weight_volume: '',
				cargo_type: null,
				veterinary: false,
				adr: false,
				comment: '',
				vehicle_count: '',
				currency: currencies[0].value,
				is_price_total: true,
				crm_id: '',
				kilkistDniv: ''
			});
		}
	};

	const [initialValues, setInitialValues] = useState(getInitialValues());

	useEffect(() => {setInitialValues(getInitialValues);},[currentLog]);

	useEffect(() => {
		if(isSuccess && !isLoading){
			clear();
			if(currentPage === 1){
				refetch();
			} else {
				setCurrentPage(1);
			}
			enqueueSnackbar('Помилку виправлено, запис збережено', {variant: 'success'});
		}
	}, [isLoading]);

	const getErrorStatus = (error: ErrorStatus) => {
		switch (error) {
		case ErrorStatus.error_when_try_get_fields: return'Помилка під час спроби отримати поля';
		case ErrorStatus.error_when_try_parsed: return'Помилка під час спроби парсингу';
		case ErrorStatus.failed_to_parse_address: return'Помилка під час парсингу адреси';
		case ErrorStatus.failed_to_save_parsed_data: return'Помилка під час спроби збереження данних';
		case ErrorStatus.request_created: return'Помилка при створенні запиту';
		case ErrorStatus.fixed_manually: return'Помилка виправлена';
		}
	};

	const getPayload = (payload: string): string => {
		try{
			return JSON.parse(payload);
		} catch (e){
			return payload;
		}
	};

	return (currentLog ?
		<Container sx={{pt: '28px'}}>
			<Card sx={{mb: '36px'}}>
				<CardContent>
					<Alert severity={currentLog.status === ErrorStatus.fixed_manually ? 'success' : 'warning'}>{getErrorStatus(currentLog.status)}</Alert>
					<Container sx={{mt: '12px'}}>
						<Typography variant="button" display="block" gutterBottom>
							Повідомлення про помилку:
						</Typography>
						<Typography variant="body1" gutterBottom>
							{currentLog.error_message}
						</Typography>
						<Typography mt='16px' variant="button" display="block" gutterBottom>
							Данні парсингу:
						</Typography>
						<Typography variant="body1" gutterBottom>
							{getPayload(currentLog.payload?.text ?? '')}
						</Typography>
					</Container>
				</CardContent>
			</Card>
			<FixErrorForm isLoading={isLoading} errorsFromServer={handledErrors} initialValues={initialValues} currencies={currencies} submitForm={submitForm} logists={logists}/>
		</Container> :
		<Loader/>
	);
};

export default FixErrorLogComponent;