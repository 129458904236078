import React, {useState} from 'react';
import {Autocomplete} from '@react-google-maps/api';
import {SxProps, TextField, Theme} from '@mui/material';
import {useGoogleMapApi} from '../../hook/useGoogleMapApi';

interface AutocompleteComponentProps {
  fullAddress: string;
  setFullAddress: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  required?: boolean | undefined;
  placeholder?: string | undefined;
  error?: boolean;
  helperText?: string;
  sx?: SxProps<Theme> | undefined
}

export const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
	fullAddress,
	setFullAddress,
	label,
	required,
	placeholder,
	error,
	helperText,
	sx
}) => {
	const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const {isLoaded} = useGoogleMapApi(map, setMap);
	
	const onAutoCompleteIsLoad = (autocomplete:  google.maps.places.Autocomplete) => {
		setAutocomplete(autocomplete);
	};

	return (
		<React.Fragment>
			{isLoaded && <Autocomplete
				onLoad={onAutoCompleteIsLoad}
				onPlaceChanged={() => {
					setFullAddress(autocomplete?.getPlace()?.formatted_address ?? fullAddress);
				}}
			>
				<TextField
					id="autocomplete"
					placeholder={placeholder}
					name="autocomplete"
					value={fullAddress}
					onChange={(event) => setFullAddress(event.target.value)}
					required={required}
					label={label}
					error={error}
					helperText={helperText}
					sx={sx}
					fullWidth
				/>
			</Autocomplete>}
		</React.Fragment>
	);
};
