import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {TrailerType} from '../../types';
import {capitalizeFirstLetter} from '../../services/capitalizeFirstLetter';

interface Props {
  value: 'all' | TrailerType;
  setValue: React.Dispatch<React.SetStateAction<'all' | TrailerType>>;
}

export const SelectTrailer: React.FC<Props> = ({
	value,
	setValue,
}) => {

	return (
		<FormControl sx={{m: 1, minWidth: 180}} className="full-width">
			<InputLabel id="select-trailer-label">Тип причепа</InputLabel>
			<Select
				labelId="select-trailer-label"
				className="bg-white"
				value={value}
				label='Тип причепа'
				//@ts-ignore
				onChange={(event) => setValue(event.target.value)}
			>
				<MenuItem value='all'>Всі</MenuItem>
				{Object.values(TrailerType).map((value) => (
					<MenuItem key={value} value={value}>{capitalizeFirstLetter(value)}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
