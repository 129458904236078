import React from 'react';
import {Box, Container} from '@mui/material';
import FiltersBar from '../components/FiltersBar';
import {Logist} from '../../types';
import useAxios from '../../hook/useAxios';
import {getReports} from '../api/api';
import {ReportReq, ReportRes} from '../types';
import {useHandleError} from '../../hook/useHandleError';
import ReportDisplay from '../components/ReportDisplay';

interface Props {
	logistList: Logist[]
}
const ReportPage: React.FC<Props> = ({logistList}) => {
	
	const {fetchData, data, isLoading, isSuccess, error} = useAxios<ReportRes, ReportReq>(getReports, null);
	useHandleError(error);

	return (
		<Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
			<Box sx={{maxWidth: '900px'}}>
				<FiltersBar
					logistList={logistList}
					fetch={fetchData}
					isLoading={isLoading}
				/>
				<ReportDisplay
					data={data}
					isSuccess={isSuccess}
				/>
			</Box>
		</Container>
	);
};

export default ReportPage;