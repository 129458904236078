import React from 'react';
import './home.scss';
import {GoogleMapPage} from '../../components/google-map-page/GoogleMapPage';
import {Filter} from '../../components/filter/Filter';
import {ICargo, ICarrier, Logist} from '../../types';

interface HomeProps {
  map: google.maps.Map | null;
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  cars: ICarrier[];
  setCars: React.Dispatch<React.SetStateAction<ICarrier[]>>;
  cargos: ICargo[];
  setCargos: React.Dispatch<React.SetStateAction<ICargo[]>>;
  logists: Logist[];
}

export const Home: React.FC<HomeProps> = ({
	map,
	setMap,
	cars,
	setCars,
	cargos,
	setCargos,
	logists
}) => {

	return (
		<>
			<Filter map={map}
				setCars={setCars}
				setCargos={setCargos}
				logists={logists}
			/>
			<div className="google-map-wrapper">
				<GoogleMapPage map={map}
					setMap={setMap}
					carrier={cars}
					cargos={cargos}
				/>
			</div>
		</>
	);
};
