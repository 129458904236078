import React, {useEffect, useState} from 'react';
import {ICargo, ICarrier, LocalStorage} from '../../types';
import MapWithClusters from './MapWithClusters';
import MapWithoutClusters from './MapWithoutClusters';

interface GoogleMapPageProps {
	map: google.maps.Map | null;
	setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
	carrier: ICarrier[]
	cargos: ICargo[]
}

export const GoogleMapPage: React.FC<GoogleMapPageProps> = ({
	map,
	setMap,
	carrier,
	cargos
}) => {
	const [isGroupMode, setIsGroupMode] = useState(localStorage.getItem(LocalStorage.isGrouped) === 'true');
	useEffect(() => {
		localStorage.setItem(LocalStorage.isGrouped, `${isGroupMode}`);
	}, [isGroupMode]);

	if(isGroupMode){
		return (
			<MapWithClusters map={map} setMap={setMap} carrier={carrier} cargos={cargos} isGroupMode={isGroupMode} setIsGroupMode={setIsGroupMode}/>
		);
	} else {
		return (
			<MapWithoutClusters map={map} setMap={setMap} carrier={carrier} cargos={cargos} isGroupMode={isGroupMode} setIsGroupMode={setIsGroupMode}/>
		);
	}
};
