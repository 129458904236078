import React, {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Tabs, Tab, IconButton, Box} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {AuthContext} from '../app/App';

const Navigation = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const {setIsAuth} = useContext(AuthContext);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		navigate(newValue);
	};

	return (
		<Box sx={{width: '100%', position: 'relative'}}>
			<Tabs
				value={pathname}
				onChange={handleChange}
				centered
			>
				<Tab label="Мапа" value="/" />
				<Tab label="Список перевізників і вантажу" value="/list" />
				<Tab label="Додати перевізника" value="/carrier" />
				<Tab label="Угоди" value="/deals" />
				<Tab label="Чернетка вантажів" value="/cargo-drafts" />
				<Tab label="Звіт" value="/report" />
			</Tabs>
			<IconButton
				size='small'
				sx={{position: 'absolute', right: '12px', top: '7px'}}
				onClick={() => {
					setIsAuth(false);
					localStorage.removeItem('token');
				}}
			>
				<LogoutIcon />
			</IconButton>
		</Box>
	);
};

export default Navigation;