import {AxiosRequestConfig} from 'axios';
import {FixParsingErrorReq} from '../types';

export const getParsingErrorLogsList = (page: number = 1): AxiosRequestConfig => ({
	url: '/parsing-error-logs',
	params: {
		page,
		per_page: 30
	}
});

export const fixParsingError = (request: FixParsingErrorReq): AxiosRequestConfig => ({
	method: 'POST',
	url: `/parsing-error-logs/${request.errorId}/fix`,
	data: request.data
});