import React from 'react';
import Navigation from '../components/navigation';
import {Outlet} from 'react-router-dom';

const Layout = () => {
	return (
		<React.Fragment>
			<Navigation/>
			<Outlet/>
		</React.Fragment>
	);
};

export default Layout;