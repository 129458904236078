import {ApiErrorResponse} from './isApiError';

enum ErrorTypes {
	type= 'type',
	required = 'required',
	unique = 'unique',
	tooLong = 'tooLong',
	invalidValue = 'invalidValue',
	numberInBetween = 'numberInBetween'
}

const knownErrorMessages: {rule: RegExp, type: ErrorTypes}[] = [
	{
		rule: /^The .+? field must be a (boolean|string|number|date)\.$/,
		type: ErrorTypes.type
	},
	{
		rule: /^The .+? field is required\.$/,
		type: ErrorTypes.required
	},
	{
		rule: /^The .+? field must be unique\.$/,
		type: ErrorTypes.unique
	},
	{
		rule: /^The .+? field may not be greater than .+? characters\.$/,
		type: ErrorTypes.tooLong
	},
	{
		rule: /^The selected .+? is invalid\.$/,
		type: ErrorTypes.invalidValue
	},
	{
		rule: /^The .+? field must have between .+? and .+? digits\.$/,
		type: ErrorTypes.numberInBetween
	}
];

const checkMatch = (rule: RegExp, error: string) => {
	return rule.test(error);
};

const getTypeError = (string: string): ErrorTypes | null => {
	const matchedError = knownErrorMessages.find(ruleObj => checkMatch(ruleObj.rule, string));
	return matchedError ? matchedError.type : null;
};

const getTypeFieldInString = (string: string) => {
	const regex = /must be a (\w+)\./;
	const match = string.match(regex);
	const type = match ? match[1] : null;

	switch (type) {
	case 'string': return 'текстом';
	case 'number': return 'числом';
	case 'date': return 'датою';
	case 'boolean': return 'boolean';
	default: return '(інший тип)';
	}
};

const getGap = (string: string) => {
	const regex = /\d+/g;
	const matches = string.match(regex);
	if (matches){
		return {from: matches[0], to: matches[1]};
	} else {
		return {from: null, to: null};
	}
};

const getLength = (string: string) => {
	const regex = /\d+/g;
	const matches = string.match(regex);
	return matches ? matches[0] : null;
};

const errorResponse = (error: string, type: ErrorTypes | null) => {

	switch (type) {
	case ErrorTypes.required: return 'Обов\'язкове поле для заповнення';
	case ErrorTypes.invalidValue: return 'Обрано недопустиме значення';
	case ErrorTypes.unique: return 'Значення вже використовується';
	case ErrorTypes.type: return `Значення повинно бути ${getTypeFieldInString(error)}`;
	case ErrorTypes.numberInBetween: return `Число повинно бути у проміжку між ${getGap(error).from} і ${getGap (error).to}`;
	case ErrorTypes.tooLong: return `Значення не може перевищувати кількість символів: ${getLength(error)}`;
	}
};

export interface ErrorsFound {
	[key: string]: string | undefined
}
export const knownErrorHandling = (error: ApiErrorResponse): ErrorsFound | false => {
	const res: ErrorsFound = {};
	if (error.status === 422){
		res.snackbar = 'Помилка заповнення форми';
	}
	Object.keys(error.data.errors ?? {}).forEach((key) => {
		if(error.data.errors && error.data.errors[key]){
			const handleError = error.data.errors[key].find(i => getTypeError(i));
			const typeError = handleError ? getTypeError(handleError) : undefined;
			if(handleError && typeError){
				res[key] = errorResponse(handleError, getTypeError(handleError));
			} else {
				res[key] = 'Помилка значення';
			}
		}
	});

	return Object.keys(res).length > 0 ? res : false;
};