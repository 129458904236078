import {useEffect, useState} from 'react';

export const useShowError = (error: string | undefined, isTouched: boolean | undefined, isErrorFromServer: boolean) => {
	const [isShowError, setIsShowError] = useState(false);

	useEffect(() => {
		if(!!(error && isTouched) && !isShowError){
			setIsShowError(true);
		} else if(isShowError){
			setIsShowError(false);
		}
	},[error, isTouched]);

	useEffect(() => {
		if(isErrorFromServer && error){
			setIsShowError(true);
		}
	}, [isErrorFromServer]);

	return isShowError;
};