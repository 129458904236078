export enum Grouping {
	date = 'date',
	logist = 'logist'
}

export interface ReportReq {
	group_by: Grouping
	filters: {
		logists: string[]
		created_at:{
			from: string
			to: string
		}
	}
}

export interface ReportRes {
	data: Report[]
	totals: {
		carriers_total: number
		cargos_total: number
		deals_total: number
	}
}

interface Report {
	date: string
	carriers_count: number
	cargos_count: number
	deals_count: number
	logists: string
}

