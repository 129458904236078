import React, {useEffect, useState} from 'react';
import {SelectLogist} from './SelectLogist';
import {Box, CircularProgress, FormControlLabel} from '@mui/material';
import {DatePickerComponent} from '../date-range-picker/DatePickerComponent';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {ICargo, ICarrier, LocalStorage, Logist, RecordType, ShowReq, ShowRes, TrailerType} from '../../types';
import useAxios from '../../hook/useAxios';
import {showConfig} from '../../http/api';
import {SelectTrailer} from './SelectTrailer';
import {SelectOrderType} from './SelectOrderType';
import {useSnackbar} from 'notistack';

interface FilterProps {
  map: google.maps.Map | null;
  setCars: React.Dispatch<React.SetStateAction<ICarrier[]>>;
  setCargos: React.Dispatch<React.SetStateAction<ICargo[]>>;
  logists: Logist[];
}

export const Filter: React.FC<FilterProps> = ({
	map, setCars, setCargos, logists
}) => {
	const {enqueueSnackbar} = useSnackbar();

	const now = Date.now();
	const defDateStart = localStorage.getItem(LocalStorage.mainFilterDateStart) ?
		dayjs(new Date(JSON.parse(localStorage.getItem(LocalStorage.mainFilterDateStart) ?? ''))) :
		dayjs(new Date(now - 604800000));
	const defDateEnd = localStorage.getItem(LocalStorage.mainFilterDateEnd) ?
		dayjs(new Date(JSON.parse(localStorage.getItem(LocalStorage.mainFilterDateEnd) ?? ''))) :
		dayjs(new Date());
	const [dateStart, setDateStart] = useState<dayjs.Dayjs>(defDateStart);
	const [dateEnd, setDateEnd] = useState<dayjs.Dayjs>(defDateEnd);

	const [order, setOrder] = useState<'all' | RecordType>(localStorage.getItem(LocalStorage.mainFilterOrder) as RecordType ?? 'all');
	const [trailer, setTrailer] = useState<'all' | TrailerType>(localStorage.getItem(LocalStorage.mainFilterTrailer)as TrailerType ?? 'all');
	const [logist, setLogist] = useState(localStorage.getItem(LocalStorage.mainFilterLogist) ?? 'all');
	const [checked, setChecked] = useState(localStorage.getItem(LocalStorage.mainFilterLost) === 'true');
	const [controller, setController] = useState<null | AbortController>(null);
	const [isLoadNewReq, setIsLoadNewReq] = useState(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};
	const {fetchData, data, isLoading, isSuccess, isError} = useAxios<ShowRes, ShowReq>(showConfig, null);

	const [fetchTimeout, setFetchTimeout] = useState<null | ReturnType<typeof setTimeout>>(null);
	const getRequestData = (): ShowReq['req'] => ({
		order_type: order === 'all' ? [RecordType.cargo, RecordType.carrier] : [order],
		filters: {
			logist: logist === 'all' ? '' : logist,
			trailer_type: trailer === 'all' ? [] : [trailer],
			created_at: {
				from: dateToDayMonthYearString(new Date(dateStart.toString())),
				to: dateToDayMonthYearString(new Date(dateEnd.toString()))
			},
			is_lost: checked ? '1' : '0',
			radius: {
				center_point_latitude: map?.getCenter()?.lat().toString() ?? '0',
				center_point_longitude: map?.getCenter()?.lat().toString() ?? '0',
				max_distance: 40076
			}
		}
	});

	useEffect(() => {
		if (fetchTimeout) {
			clearTimeout(fetchTimeout);
			setFetchTimeout(null);
		}
		if(controller){
			controller.abort();
			setController(null);
			setIsLoadNewReq(true);
			setTimeout(() => {
				nextFetch();
			}, 0);
		} else {
			const newController: AbortController = new AbortController();
			setController(newController);
			setIsLoadNewReq(true);
			fetchData({controller: newController, req: getRequestData()});
		}
		localStorage.setItem(LocalStorage.mainFilterDateStart, JSON.stringify(dateStart));
		localStorage.setItem(LocalStorage.mainFilterDateEnd, JSON.stringify(dateEnd));
		localStorage.setItem(LocalStorage.mainFilterOrder, order);
		localStorage.setItem(LocalStorage.mainFilterLogist, logist);
		localStorage.setItem(LocalStorage.mainFilterTrailer, trailer);
		localStorage.setItem(LocalStorage.mainFilterLost, checked ? 'true' : 'false');

	}, [order, trailer, logist, dateStart, dateEnd, checked]);
	const nextFetch = () => {
		const newController: AbortController = new AbortController();
		setController(newController);
		fetchData({controller: newController, req: getRequestData()});
	};
	useEffect(() => {
		if(data && isSuccess && !isLoading){
			setCars(data.carriers);
			setCargos(data.cargos);
			if(isLoadNewReq){
				setIsLoadNewReq(false);
			}
			setFetchTimeout(setTimeout(() => {
				nextFetch();
			}, 5000));
		} else if(isError && !isLoading){
			enqueueSnackbar('Сталася помилка', {variant: 'error'});
		}
	}, [isLoading]);

	return (<div className="form-wrapper">
		<Box sx={{backgroundColor: 'white', flex: 1, borderRadius: '12px'}}>
			<Box sx={{display: 'flex', alignItems: 'center', m: 0, justifyContent: 'space-between'}}>
				<SelectOrderType value={order} setValue={setOrder}/>
				<SelectTrailer value={trailer} setValue={setTrailer}/>
				<SelectLogist
					filterList={logists}
					value={logist}
					setValue={setLogist}
				/>
				<Box sx={{p: 1}} className="full-width">
					<DatePickerComponent
						label="Дата початок"
						date={dateStart}
						setDate={setDateStart}
						maxDate={dateEnd}
					/>
				</Box>
				<Box sx={{p: 1}} className="full-width">
					<DatePickerComponent
						label="Дата кінець"
						date={dateEnd}
						setDate={setDateEnd}
						minDate={dateStart}
					/>
				</Box>
				<Box sx={{p: 1}}>
					<FormControlLabel control={<Checkbox
						checked={checked}
						onChange={handleChange}
						inputProps={{'aria-label': 'Втрачені'}}
					/>} label="Втрачені"/>
				</Box>
			</Box>
			{isLoadNewReq && <Box sx={{position: 'relative', top: '16px', width: '100%', height: '0px', display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>}
		</Box>
	</div>);
};
