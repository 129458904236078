import {AxiosRequestConfig} from 'axios';
import {DealsReq} from '../types';

export const getDeals = (params: DealsReq): AxiosRequestConfig => ({
	url: '/deals',
	params: params.req,
	signal: params.controller.signal
});

export const deleteDeal = (dealId: string | number): AxiosRequestConfig => ({
	url: `/deals/${dealId}`,
	method: 'DELETE'
});