import React from 'react';
import {ICarrier} from '../../types';
import {Clusterer} from '@react-google-maps/marker-clusterer';
import CarrierMarker from './CarrierMarker';

interface Props {
	carrier: ICarrier[]
	marker: string | null,
	setMarker: (value: string | null) => void,
	clusterer?: Clusterer,
}

const CarrierMarkerList: React.FC<Props> = ({carrier, marker, setMarker, clusterer}) => {
	const groupCarrierMarkersByPosition = (markers: ICarrier[]): ICarrier[][] => {
		const groupedMarkers: { [key: string]: ICarrier[] } = {};

		markers.forEach(marker => {
			const key = `${marker.car_location_lat}-${marker.car_location_lon}`;
			if (!groupedMarkers[key]) {
				groupedMarkers[key] = [];
			}
			groupedMarkers[key].push(marker);
		});

		return Object.values(groupedMarkers);
	};

	const distributeMarkersInSemiCircle = (markers: ICarrier[]): ICarrier[] => {
		const radius = 0.1;
		const angleStep = Math.PI / (markers.length - 1);
		const startAngle = Math.PI;

		return markers.map((marker, index) => {
			const angle = startAngle + index * angleStep;
			const offsetLat = radius * Math.cos(angle);
			const offsetLng = radius * Math.sin(angle)*1.5;

			return {
				...marker,
				car_location_lat: `${+marker.car_location_lat + offsetLat}`,
				car_location_lon: `${+marker.car_location_lon + offsetLng}`,
			};
		});
	};
	
	return (
		<React.Fragment>
			{groupCarrierMarkersByPosition(carrier).flatMap((group) => {
				if (group.length > 1) {
					const distributedMarkers = distributeMarkersInSemiCircle(group);
					return distributedMarkers.map(carrier => (
						<CarrierMarker key={carrier.id} carrier={carrier} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
					));
				} else {
					return (
						<CarrierMarker key={group[0].id} carrier={group[0]} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
					);
				}
			})}
		</React.Fragment>
	);
};

export default CarrierMarkerList;