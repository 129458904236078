import {useState, useEffect, useContext} from 'react';
import { AxiosRequestConfig } from 'axios';
import $api from '../http';
import {ApiErrorResponse, isApiError} from '../services/isApiError';
import {AuthContext} from '../components/app/App';

export interface ApiResponse<T, U> {
    data?: T;
	isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    error?: ApiErrorResponse;
	fetchData: (req?: U) => Promise<void>
}


const useAxios = <T, U>(config: (payload: U)  => AxiosRequestConfig, payload: U | null): ApiResponse<T, U> => {
	const { setIsAuth } = useContext(AuthContext);
	
	const [apiResponse, setApiResponse] = useState<Omit<ApiResponse<T, U>, 'fetchData'>>(
		{
			data: undefined,
			isSuccess: false,
			isLoading: false,
			isError: false,
			error: undefined
		}
	);
	const fetchData = async (req?: U) => {
		if(req !== undefined || payload) {
			setApiResponse(lastResponse => ({
				data: lastResponse.data,
				isSuccess: lastResponse.isSuccess,
				isLoading: true,
				isError: lastResponse.isError,
				error: lastResponse.error
			}));

			try {
				const response = await $api(config(req ? req : payload!));
				setApiResponse({
					data: response.data,
					isSuccess: true,
					isLoading: false,
					isError: false,
					error: undefined
				});
			} catch (error) {
				console.error(error);
				if(isApiError(error) && error.response.status === 401){
					setIsAuth(false);
					localStorage.removeItem('token');
				}
				const errorObj: ApiErrorResponse = isApiError(error) ? error.response : {
					status: 0,
					data: {
						message: JSON.stringify(error)
					}
				};
				if(typeof error === 'object' && error !==null && 'code' in error && error.code === 'ERR_CANCELED'){
					setApiResponse(lastResponse => ({
						data: lastResponse.data,
						isSuccess: lastResponse.isSuccess,
						isLoading: false,
						isError: lastResponse.isError,
						error: lastResponse.error
					}));
				} else {
					setApiResponse(lastResponse => ({
						data: lastResponse.data,
						isSuccess: false,
						isLoading: false,
						isError: true,
						error: errorObj
					}));
				}
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, [payload]);

	return {...apiResponse, fetchData};
};

export default useAxios;
