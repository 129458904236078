import {useEffect, useState} from 'react';
import {ApiErrorResponse} from '../services/isApiError';
import {useSnackbar} from 'notistack';
import {ErrorsFound, knownErrorHandling} from '../services/knownErrorHandling';

export const useHandleError = (error: ApiErrorResponse | undefined, defaultErrorMessage?: string) => {
	const [handledError, setHandledError] = useState<null | ErrorsFound>(null);
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		if(error !== undefined && error.status !== 401){
			//Handle Api error
			const result = knownErrorHandling(error);
			if(result){
				setHandledError(result);
				if(result.snackbar){
					enqueueSnackbar(result.snackbar, {variant: 'error'});
				}
			} else {
				if(handledError){
					setHandledError(null);
				}

				if(error.status === 500){
					enqueueSnackbar('Помилка серверу', {variant: 'error'});
				} else {
					enqueueSnackbar(defaultErrorMessage ?? 'Сталася помилка, спробуйте пізніше', {variant: 'error'});
				}
			}
		}
	},[error]);

	return handledError;
};
