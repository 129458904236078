import React, {useState} from 'react';
import {Container} from '@mui/material';
import ParsingErrorsList from '../components/ParsingErrorsList';
import FixErrorLogComponent from '../components/FixErrorLogComponent';
import {ErrorLog} from '../types';
import useAxios from '../../hook/useAxios';
import {List as ListType} from '../types/response';
import {getParsingErrorLogsList} from '../api/api';
import {Logist} from '../../types';

interface Props {
	logists: Logist[]
}
const CargoDrafts: React.FC<Props> = ({logists}) => {
	const [currentLog, setCurrentLog] = useState<ErrorLog|null>(null);

	const [currentPage, setCurrentPage] = useState(1);
	const apiResponse = useAxios<ListType<ErrorLog>, number>(getParsingErrorLogsList, currentPage);

	return (
		<Container sx={{width: '100%', display: 'flex'}}>
			<FixErrorLogComponent logists={logists} currentPage={currentPage} setCurrentPage={setCurrentPage} currentLog={currentLog} refetch={apiResponse.fetchData} clear={() => setCurrentLog(null)}/>
			<ParsingErrorsList setCurrentLog={setCurrentLog} currentLog={currentLog} apiResponse={apiResponse} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
		</Container>
	);
};

export default CargoDrafts;