import React, {createContext, useState} from 'react';
import './App.scss';
import Routes from '../../routes';

interface AuthContextInterface {
	isAuth: boolean
	setIsAuth: (value: boolean) => void
}
export const AuthContext = createContext<AuthContextInterface>({isAuth: false, setIsAuth: () => null});

export const App = () => {
	const [isAuth, setIsAuth] = useState(!!localStorage.getItem('token'));

	return (
		<AuthContext.Provider value={{isAuth, setIsAuth}}>
			<div className="App container">
				<Routes/>
			</div>
		</AuthContext.Provider>
	);
};
