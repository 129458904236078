import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Logist} from '../../types';

interface SelectLogistProps {
  filterList: Logist[];
  value: string;
  setValue: (value: string) => void;
  mainFilter?: boolean
}

export const SelectLogist: React.FC<SelectLogistProps> = ({
	filterList,
	value,
	setValue,
	mainFilter = true
}) => {

	const handleChange = (  event: SelectChangeEvent<typeof value>) => {
		setValue(event.target.value);
	};

	return (
		<FormControl sx={mainFilter ? {m: 1, minWidth: 180} : {mb: '6px'}} fullWidth>
			<InputLabel id="logist-select-label">Логіст</InputLabel>
			<Select
				labelId="logist-select-label"
				className="bg-white"
				value={value}
				label='Логіст'
				onChange={handleChange}
			>
				<MenuItem value="all">
					Всі
				</MenuItem>
				{filterList?.map((item) => {
					return <MenuItem key={item.login}
						value={item.login}
					>
						{item.name ? item.name : item.login}
					</MenuItem>;
				})}
			</Select>
		</FormControl>
	);
};
