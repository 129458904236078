import {Dayjs} from 'dayjs';
import {FixForm} from '../cargoDrafts/types';

export interface ICargo {
	address_from: string
	address_to: string
	adr: null | 0 | 1
	available_from: string
	available_until: string
	cargo_type: string
	counterparty: null | boolean
	created_at: string
	customs_clearance: string
	deal: null | 1 | 0
	distance_from_center_in_km: number
	estimated_cost: number
	id: string
	loading_point_lat: string
	loading_point_lon: string
	order_creation_date: string
	order_number: null
	responsible_person: null | string
	trailer_type: TrailerType
	type_of_record: RecordType
	updated_at: string
	weight_volume: string
	crm_id: null | string
	destination_point_lat: string
	destination_point_lon: string
	vehicle_count: null | number
	comment: null | string
	currency: Currencies | null
	is_price_total: 0 | 1
	veterinary: null | 0 | 1
	logist: null | string
	available_days_count: null | number
	distance_from_center_in_km_to_destination: number
	logist_details: LogistDetails
}

export interface ICarrier {
	address: string;
	adr: number;
	available_from: string;
	available_until: string;
	car_location_lat: string;
	car_location_lon: string;
	created_at: string;
	deal: null | boolean;
	destination_location1: null | string;
	destination_location2: null | string;
	destination_location3: null | string;
	distance_from_center_in_km: number;
	estimated_amount: number;
	crm_id: string;
	id: string;
	trailer_type: TrailerType;
	type_of_record: RecordType;
	updated_at: string;
	weight: string;
	comment: null | string;
	currency: Currencies | null;
	is_price_total: 0 | 1;
	additional_load: 0 | 1;
	logist: null | string;
	private_comment: null | string;
	logist_details: null | LogistDetails;
}
export enum TrailerType {
	tent = 'тент',
	refrigerator = 'рефрижератор',
	tanker = 'цистерна',
	container_truck = 'контейнеровоз',
	grain_truck = 'зерновоз',
	isotherm = 'ізотерм',
	trawl = 'трал',
	target_meter = 'цільномет',
	car_carrier = 'автовоз',
	bus = 'бус',
	platform = 'платформа',
	tank_truck = 'бензовоз',
	dump_truck = 'самоскид'
}

export interface Logist {
	name: string
	login: string
}

export interface LogistsNames {
	[login: string]: string
}

export interface LogistDetails {
	id: number,
	login: string,
	name: string
}

export enum CurrenciesSymbol {
	UAH = '₴',
	EUR = '€',
	USD = '$',
}

export enum Currencies {
	UAH = 'UAH',
	EUR = 'EUR',
	USD = 'USD'
}

export enum RecordType {
	cargo='cargo',
	carrier = 'carrier'
}

export interface ShowReq {
	controller: AbortController
	req: {
		order_type: [RecordType, ...RecordType[]]
		filters: {
			logist: string
			trailer_type: TrailerType[]
			created_at: {
				from: string, to: string
			},
			is_lost: '1' | '0',
			radius: {
				center_point_latitude: string,
				center_point_longitude: string,
				max_distance: 40076
			}
		}
	}
}

export interface ShowRes {
	carriers: ICarrier[],
	cargos: ICargo[]
}

export interface PostCarriersReq {
	address: string
	destination_location1: string
	destination_location2: string | null
	destination_location3: string | null
	trailer_type: TrailerType
	available_from: string  | null
	available_until: string | null
	weight: number | null
	estimated_amount: number | null
	currency: Currencies | null
	adr: boolean
	is_price_total: boolean | null
	additional_load: boolean
	comment: string | null
	private_comment: string | null
}

export interface FormCarriers {
	address: string
	destination_location1: string
	destination_location2: string
	destination_location3: string
	trailer_type: TrailerType
	available_from:  Dayjs  | null
	available_until:  Dayjs | null
	weight: string | null
	estimated_amount: string
	currency: Currencies
	adr: boolean
	is_price_total: boolean | 'true' | 'false'
	additional_load: boolean
	comment: string | null
	private_comment: string | null
}

export enum LocalStorage {
	token= 'token',
	login = 'login',
	mainFilterDateStart = 'mainFilterDateStart',
	mainFilterDateEnd = 'mainFilterDateEnd',
	mainFilterOrder = 'mainFilterOrder',
	mainFilterTrailer = 'mainFilterTrailer',
	mainFilterLogist = 'mainFilterLogist',
	mainFilterLost = 'mainFilterLost',
	isGrouped = 'isGrouped',
}

export type EditCargoForm = Omit<FixForm, 'crm_id'|'kilkistDniv'|'order_creation_date'>

export interface EditCargoReq {
	data: {
		logist: string
		address_from: string
		address_to: string
		trailer_type: TrailerType
		estimated_cost: number | null
		available_from: string
		available_until: string
		weight_volume: null | number
		veterinary: boolean | null
		adr: boolean | null
		comment: string | null
		vehicle_count: number | null
		currency: string | null
		is_price_total: boolean | null
	}
	id: string | number
}

export interface EditCarrierReq {
	data: PostCarriersReq,
	id: string | number
}