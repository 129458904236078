import React, {useState} from 'react';
import {IconButton, TextField, Typography} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useSnackbar} from 'notistack';
import {ICarrier, LocalStorage} from '../../types';
import {getLogistName} from '../../services/getLogistName';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {getCurrencyAndType} from '../../services/getCurrencyAndType';
import EditIcon from '@mui/icons-material/Edit';
import EditCarrier from '../EditCarrier';

interface CarrierListProps {
  list: ICarrier[];
  setList: React.Dispatch<React.SetStateAction<ICarrier[]>>;
  setCarrierId: React.Dispatch<React.SetStateAction<string>>;
}

export const CarrierList: React.FC<CarrierListProps> = ({
	list,
	setList,
	setCarrierId
}) => {
	const [editCarrier, setEditCarrier] = useState<null | ICarrier>(null);
	const [term, setTerm] = useState<string>('');
	const {enqueueSnackbar} = useSnackbar();
	const handleClickCarrier = (id: string) => {
		setCarrierId(id);
	};
	const login = localStorage.getItem(LocalStorage.login);
	const search = (data: ICarrier[], value: string) => {
		if (value.length === 0) {
			return data;
		} else return data.filter(item => item.id == value);
	};

	const visibleData = search(list, term);

	const deleteItem = async (id: string) => {
		await fetch(`https://mapbot.testprojects.net/api/v1/carriers/${id}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem('token')
			}
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					enqueueSnackbar('Видалено', {variant: 'success'});
					setList(item => item.filter(carrier => carrier.id != id));
					return response.json();
				} else if (response.status >= 400) {
					enqueueSnackbar('Cталася помилка', {variant: 'error'});
					throw Error(response.statusText);
				}
			})
			.then(data => console.log(data))
			.catch(error => console.error(error));
	};

	const displayValue = (label: string, value: string | null) => (
		<span style={{marginRight: '6px'}}><b>{label}:</b> {value ?? '---'}.</span>
	);
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	return (
		<div className="carrier-list-block">
			<h3>Перевізники</h3>
			<TextField
				variant="outlined"
				fullWidth
				value={term}
				onChange={event => setTerm(event.target.value)}
				placeholder="Пошук по ID"/>
			<ul className="carrier-list">
				{visibleData.map((carrier) => {
					return <li key={carrier.id}
						onClick={() => handleClickCarrier(carrier.id)}>
						<div className="carrier-radio-button">
							<input
								type="radio"
								name="carrierId"
								value={carrier.id}
							/>
						</div>
						<div>
							{displayValue('CRM ID', carrier.crm_id)}
							{displayValue('Логіст', getLogistName(logistsList, carrier.logist ?? '---'))}
							{displayValue('Адреса', carrier.address)}
							{displayValue('Довантаження', carrier.additional_load ? 'так' : 'ні')}
							{displayValue('Місце призначення 1', carrier.destination_location1)}
							{carrier.destination_location2 && displayValue('Місце призначення 2', carrier.destination_location2)}
							{carrier.destination_location3 && displayValue('Місце призначення 3', carrier.destination_location3)}
							{displayValue('ADR', carrier.adr === 1 ? 'Так' : 'Ні')}
							{displayValue('Доступний з', dateToDayMonthYearString(new Date(carrier.available_from)))}
							{displayValue('Доступний до', dateToDayMonthYearString(new Date(carrier.available_until)))}
							{displayValue('Створено', dateToDayMonthYearString(new Date(carrier.created_at)))}
							{displayValue('Тип причепа', carrier.trailer_type)}
							{displayValue('Вага', carrier.weight ? carrier.weight + 'т' : '---')}
							{displayValue('Орієнтовна сума', carrier.estimated_amount ? carrier.estimated_amount.toString() + ' ' + getCurrencyAndType(carrier.currency, carrier.is_price_total) : '---')}
							{displayValue('Коментар', carrier.comment)}
							{carrier.private_comment && displayValue('Приватний коментар', carrier.private_comment)}
						</div>
						{login === carrier.logist &&
							<IconButton onClick={() => deleteItem(carrier.id)}>
								<DeleteForeverIcon/>
							</IconButton>
						}
						{login === carrier.logist &&
							<IconButton onClick={() => setEditCarrier(carrier)}>
								<EditIcon/>
							</IconButton>
						}
					</li>;
				}
				)}
				{list.length === 0 && <Typography sx={{paddingTop: '50px'}} color="lightgrey">Дані відсутні, спробуйте змінити параметри фільтру</Typography>}
			</ul>
			{editCarrier && <EditCarrier currentCarrier={editCarrier} isOpen={!!editCarrier} handleClose={() => setEditCarrier(null)}/>}
		</div>
	);
};
