import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import {App} from './components/app/App';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarProvider, closeSnackbar} from 'notistack';
import {Grow} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<SnackbarProvider maxSnack={3}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				TransitionComponent={Grow}
				action={(snackbarId) => (
					<CloseIcon onClick={() => closeSnackbar(snackbarId)}/>
				)}
				autoHideDuration={3000}
				preventDuplicate
			>
				<App />
			</SnackbarProvider>
		</BrowserRouter>
	</React.StrictMode>
);
