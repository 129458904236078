import React, {useEffect, useState} from 'react';
import useAxios from '../hook/useAxios';
import {Currencies, EditCarrierReq, FormCarriers, ICarrier, TrailerType} from '../types';
import {editCarrier} from '../http/api';
import {useHandleError} from '../hook/useHandleError';
import {useSnackbar} from 'notistack';
import dayjs from 'dayjs';
import { number, object, string} from 'yup';
import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Radio,
	RadioGroup,
	TextField
} from '@mui/material';
import {Formik} from 'formik';
import {useShowError} from '../cargoDrafts/hooks/useShowError';
import {AutocompleteComponent} from './autocomplete/AutocompleteComponent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {capitalizeFirstLetter} from '../services/capitalizeFirstLetter';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import {dateToDayMonthYearString, getIso} from '../services/TimeService';

interface Props {
	currentCarrier: ICarrier
	isOpen: boolean,
	handleClose: () => void
}

const currencies = [
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'UAH',
		label: '₴',
	},
	{
		value: 'USD',
		label: '$',
	}
];

const EditCarrier: React.FC<Props> = ({currentCarrier, handleClose, isOpen}) => {
	const {fetchData, isLoading, isSuccess, error} = useAxios<void, EditCarrierReq>(editCarrier, null);
	const handledErrors = useHandleError(error);
	const {enqueueSnackbar} = useSnackbar();
	const submitForm = (values: FormCarriers) => {
		if(currentCarrier && values.available_from && values.available_until){

			const data: EditCarrierReq['data'] = {
				address: values.address,
				destination_location1: values.destination_location1,
				destination_location2: values.destination_location2 ? values.destination_location2 : null,
				destination_location3: values.destination_location3 ? values.destination_location3 : null,
				trailer_type: values.trailer_type,
				available_from:  values.available_from ?  dateToDayMonthYearString(new Date(values.available_from.toString())) : null,
				available_until: values.available_until ?  dateToDayMonthYearString(new Date(values.available_until.toString())) : null,
				weight: values.weight ? +values.weight : null,
				estimated_amount: values.estimated_amount ? +values.estimated_amount : null,
				currency: values.estimated_amount ? values.currency : null,
				is_price_total: values.estimated_amount ? (values.is_price_total === 'true' || values.is_price_total === true) : null,
				adr: values.adr,
				additional_load: values.additional_load,
				comment: values.comment ? values.comment : null,
				private_comment: values.private_comment ? values.private_comment : null
			};
			fetchData({id: currentCarrier.id, data});
		}
	};

	const getInitialValues = (): FormCarriers => {
		return ({
			address: currentCarrier.address ?? '',
			destination_location1: currentCarrier.destination_location1 ?? '',
			destination_location2: currentCarrier.destination_location2 ?? '',
			destination_location3: currentCarrier.destination_location3 ?? '',
			trailer_type: currentCarrier.trailer_type ?? TrailerType.tent,
			available_from: dayjs(new Date(getIso(currentCarrier.available_from))),
			available_until: dayjs(new Date(getIso(currentCarrier.available_until))),
			weight: currentCarrier.weight ? currentCarrier.weight.toString() : '',
			estimated_amount: currentCarrier.estimated_amount ? currentCarrier.estimated_amount.toString() : '',
			adr: !!currentCarrier.adr,
			additional_load: !!currentCarrier.additional_load,
			comment: currentCarrier.comment ?? '',
			is_price_total: !!currentCarrier.is_price_total,
			currency: currentCarrier.currency ?? Currencies.EUR,
			private_comment: currentCarrier.private_comment ?? ''
		});
	};

	const [initialValues, setInitialValues] = useState(getInitialValues());

	useEffect(() => {setInitialValues(getInitialValues);},[currentCarrier]);

	useEffect(() => {
		if(isSuccess && !isLoading){
			enqueueSnackbar('Зміни збережено', {variant: 'success'});
			handleClose();
		}
	}, [isLoading]);

	const validationSchema = object({
		address: string()
			.required('Обов\'язкове поле для вводу')
			.max(120, 'Занадто довга адреса'),
		destination_location1: string()
			.required('Обов\'язкове поле для вводу'),
		destination_location2: string(),
		destination_location3: string(),
		trailer_type: string()
			.required('Обов\'язкове поле для вводу')
			.oneOf(Object.values(TrailerType), 'Невірний тип'),
		available_from: string().required('Обов\'язкове поле для вводу'),
		available_until: string().nullable(),
		weight: number()
			.typeError('Значення повинно бути числом')
			.positive('Значення повинно бути додатнім'),
		estimated_amount: number()
			.typeError('Значення повинно бути числом')
			.positive('Значення повинно бути додатнім'),
		currency:string()
			.max(3, 'Занадто довге значення')
			.required('Обов\'язкове поле для вводу'),
		comment: string().max(15000, 'Занадто довгий коментар'),
		private_comment: string().max(15000, 'Занадто довгий коментар')
	});

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 'calc(100vh - 120px)'} }}
			open={isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>

			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					submitForm(values);
				}}
				validationSchema={validationSchema}
			>
				{({
					handleChange,
					handleBlur,
					values,
					errors,
					touched,
					setFieldValue,
					setValues,
					handleSubmit,
					setErrors,
					resetForm,
					isValid
				}) => {
					const [isErrorFromServer, setIsErrorFromServer] = useState(false);
					const isErrorAddress = useShowError(errors.address, touched.address, isErrorFromServer);
					const isErrorDestination1 = useShowError(errors.destination_location1, touched.destination_location1, isErrorFromServer);
					const isErrorDestination2 = useShowError(errors.destination_location2, touched.destination_location2, isErrorFromServer);
					const isErrorDestination3 = useShowError(errors.destination_location3, touched.destination_location3, isErrorFromServer);
					const isErrorTrailerType = useShowError(errors.trailer_type, touched.trailer_type, isErrorFromServer);
					const isErrorEstimatedAmount = useShowError(errors.estimated_amount, touched.estimated_amount, isErrorFromServer);
					const isErrorCurrency = useShowError(errors.currency, touched.currency, isErrorFromServer);
					const isErrorIsPriceTotal = useShowError(errors.is_price_total, touched.is_price_total, isErrorFromServer);
					const isErrorAvailableFrom = useShowError(errors.available_from, touched.available_from, isErrorFromServer);
					const isErrorAvailableUntil = useShowError(errors.available_until, touched.available_until, isErrorFromServer);
					const isErrorWeight = useShowError(errors.weight, touched.weight, isErrorFromServer);
					const isErrorComment = useShowError(errors.comment, touched.comment, isErrorFromServer);
					const isErrorPrivateComment = useShowError(errors.private_comment, touched.private_comment, isErrorFromServer);

					useEffect(() => {
						if(handledErrors){
							setErrors(handledErrors);
						}
					}, [handledErrors]);

					useEffect(() => {
						resetForm();
						setValues(initialValues);
					},[initialValues]);

					useEffect(() => {
						if(isValid && isErrorFromServer){
							setIsErrorFromServer(false);
						}
					},[errors]);



					return (
						<>
							<DialogTitle>Редагування перевізника</DialogTitle>
							<DialogContent>
								<Container sx={{display: 'flex', flexDirection: 'column', gap: '16px', mb: '12px', pt: '6px'}}>
									<AutocompleteComponent
										fullAddress={values.address}
										setFullAddress={(value) => setFieldValue('address', value)}
										label="Пошук адреси"
										required
										placeholder="Місто, Країна"
										error={isErrorAddress}
										helperText={isErrorAddress ? errors.address : undefined}
									/>
									<AutocompleteComponent
										fullAddress={values.destination_location1}
										setFullAddress={(value) => setFieldValue('destination_location1', value)}
										label="Пункт призначення 1"
										required
										placeholder="Місто, Країна"
										error={isErrorDestination1}
										helperText={isErrorDestination1 ? errors.destination_location1 : undefined}
									/>
									<AutocompleteComponent
										fullAddress={values.destination_location2}
										setFullAddress={(value) => setFieldValue('destination_location2', value)}
										label="Пункт призначення 2"
										placeholder="Місто, Країна"
										error={isErrorDestination2}
										helperText={isErrorDestination2 ? errors.destination_location2 : undefined}
									/>
									<AutocompleteComponent
										fullAddress={values.destination_location3}
										setFullAddress={(value) => setFieldValue('destination_location3', value)}
										label="Пункт призначення 3"
										placeholder="Місто, Країна"
										error={isErrorDestination3}
										helperText={isErrorDestination3 ? errors.destination_location3 : undefined}
									/>
									<FormControl error={isErrorTrailerType} fullWidth>
										<InputLabel id='trailer-select-label'>Тип причепа</InputLabel>
										<Select
											labelId='trailer-select-label'
											id='trailer-select'
											value={values.trailer_type}
											label='Тип причепа'
											onChange={(event) => setFieldValue('trailer_type', event.target.value)}
											error={isErrorTrailerType}
										>
											{Object.values(TrailerType).map((value) => (
												<MenuItem key={value} value={value}>{capitalizeFirstLetter(value)}</MenuItem>
											))}
										</Select>
										{(isErrorTrailerType && errors.trailer_type) && <FormHelperText>{errors.trailer_type}</FormHelperText>}
									</FormControl>
									<FormControl error={isErrorIsPriceTotal}>
										<Box sx={{display: 'flex', flexDirection: 'row'}}>
											<TextField
												value={values.estimated_amount}
												onChange={handleChange('estimated_amount')}
												onBlur={handleBlur('estimated_amount')}
												error={isErrorEstimatedAmount}
												label='Oрієнтовна ціна'
												fullWidth
												helperText={isErrorEstimatedAmount ? errors.estimated_amount : undefined}
											/>
											<TextField
												select
												value={values.currency}
												onChange={(event) => setFieldValue('currency', event.target.value)}
												helperText={isErrorCurrency ? errors.currency : undefined}
												sx={{width: '65px', ml: '6px'}}
											>
												{currencies.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</Box>
										<RadioGroup
											sx={{ml: '12px'}}
											row
											value={values.is_price_total}
											onChange={handleChange('is_price_total')}
										>
											<FormControlLabel value={true} control={<Radio />} label='Повна вартість'/>
											<FormControlLabel value={false} control={<Radio />} label='Ціна за тонну'/>
										</RadioGroup>
										{(isErrorIsPriceTotal && errors.is_price_total) && <FormHelperText>
											{errors.is_price_total}
										</FormHelperText>}
									</FormControl>
									<FormControl required error={isErrorAvailableFrom}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
											<DatePicker
												label="Доступний з"
												value={values.available_from}
												onChange={(value) => setFieldValue('available_from', value)}
												maxDate={values.available_until ?? undefined}
											/>
										</LocalizationProvider>
										{(isErrorAvailableFrom && errors.available_from) && <FormHelperText>
											{errors.available_from}
										</FormHelperText>}
									</FormControl>
									<FormControl error={isErrorAvailableUntil}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
											<DatePicker
												label="Доступний по"
												value={values.available_until}
												onChange={(value) => setFieldValue('available_until', value)}
												minDate={values.available_from ?? undefined}
											/>
										</LocalizationProvider>
										{(isErrorAvailableUntil && errors.available_until) && <FormHelperText>
											{errors.available_until}
										</FormHelperText>}
									</FormControl>
									<TextField
										value={values.weight}
										onChange={handleChange('weight')}
										onBlur={handleBlur('weight')}
										error={isErrorWeight}
										label='Вага в тоннах'
										helperText={isErrorWeight ? errors.weight : undefined}
									/>
									<TextField
										value={values.comment}
										onChange={handleChange('comment')}
										onBlur={handleBlur('comment')}
										error={isErrorComment}
										label='Коментар'
										multiline
										helperText={isErrorComment ? errors.comment : undefined}
									/>
									<TextField
										value={values.private_comment}
										onChange={handleChange('private_comment')}
										onBlur={handleBlur('private_comment')}
										error={isErrorPrivateComment}
										label='Приватний коментар'
										multiline
										helperText={isErrorPrivateComment ? errors.private_comment : undefined}
									/>
									<FormControlLabel
										control={
											<Checkbox
												onChange={(event, checked) => setFieldValue('adr', checked)}
												defaultChecked={values.adr}
											/>
										}
										label="ADR"
									/>
									<FormControlLabel
										control={
											<Checkbox
												onChange={(event, checked) => setFieldValue('additional_load', checked)}
												defaultChecked={values.additional_load}
											/>
										}
										label="Довантаження"
									/>
								</Container>
							</DialogContent>
							<DialogActions>
								<Button autoFocus onClick={handleClose}>
									Відміна
								</Button>
								<LoadingButton loading={isLoading} onClick={() => handleSubmit()}>Зберегти</LoadingButton>
							</DialogActions>
						</>
					);}}
			</Formik>
		</Dialog>
	);
};

export default EditCarrier;