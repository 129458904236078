import {Dayjs} from 'dayjs';
import {TrailerType} from '../../types';

export interface ErrorLog {
    id: number
    payload: Payload | null
    status: ErrorStatus
    response_from_bot: BotResponse | string | null | undefined
    error_message: string
    validation_log: string | null
    error_log: string | null
    created_at: string
}

export interface Payload {
    id: number,
    logist: string,
    text: string,
    kilkistDniv: number
}

export enum ErrorStatus {
    error_when_try_get_fields =  'error_when_try_get_fields',
    error_when_try_parsed = 'error_when_try_parsed',
    failed_to_parse_address = 'failed_to_parse_address',
    failed_to_save_parsed_data = 'failed_to_save_parsed_data',
    request_created = 'request_created',
    fixed_manually = 'fixed_manually'
}

export interface BotResponse {
    address_from: string | null
    address_to: string | null
    trailer_type: TrailerType | null
    estimated_cost: string | null
    available_from: string | null
    available_until: string | null
    weight_volume: string | null
    cargo_type: string | null
    veterinary:boolean
    customs_clearance: {departure?: string, arrival?: string} | null | string
    adr: boolean
}

export interface FixForm {
    logist: string
    address_from: string
    address_to: string
    trailer_type: TrailerType
    order_creation_date: string
    estimated_cost: number | string
    available_from: Dayjs | null | undefined
    available_until: Dayjs | null | undefined
    weight_volume: string
    cargo_type: null
    veterinary: boolean
    adr: boolean
    comment: string
    vehicle_count: string
    currency: string
    is_price_total: boolean | string
    crm_id: string
    kilkistDniv: string
}

export interface FixParsingErrorReq {
    errorId: ErrorLog['id'],
    data: {
        logist: string
        address_from: string
        address_to: string
        trailer_type: TrailerType
        order_creation_date: string
        estimated_cost: number | null
        available_from: string
        available_until: string
        weight_volume: null | number
        cargo_type: string | null
        veterinary: boolean | null
        adr: boolean | null
        comment: string | null
        vehicle_count: number | null
        currency: string | null
        is_price_total: boolean | null
        crm_id: number
        kilkistDniv: number
    }
}