import axios from 'axios';
import {LocalStorage} from '../types';

const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
	baseURL: API_URL,
	headers: {
		'Accept': 'application/json',
		'Content-type': 'application/json',
		'Authorization': 'Bearer ' + localStorage.getItem(LocalStorage.token)
	},
});

$api.interceptors.request.use((config) => {
	config.headers.Authorization = 'Bearer ' + localStorage.getItem(LocalStorage.token);
	return config;
});

export default $api;