import React, {useEffect, useState} from 'react';
import {dateToDayMonthYearString, getIso} from '../services/TimeService';
import {EditCargoForm, EditCargoReq, ICargo, Logist, TrailerType} from '../types';
import dayjs from 'dayjs';
import {boolean, number, object, string} from 'yup';
import {useShowError} from '../cargoDrafts/hooks/useShowError';
import {
	Box, Button,
	Container, Dialog, DialogActions, DialogContent, DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Radio,
	RadioGroup,
	TextField
} from '@mui/material';
import {AutocompleteComponent} from './autocomplete/AutocompleteComponent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {capitalizeFirstLetter} from '../services/capitalizeFirstLetter';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import {Formik} from 'formik';
import useAxios from '../hook/useAxios';
import {editCargo} from '../http/api';
import {useHandleError} from '../hook/useHandleError';
import {useSnackbar} from 'notistack';

interface Props {
	currentCargo: ICargo
	isOpen: boolean,
	handleClose: () => void
	logists: Logist[]
}
const currencies = [
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'UAH',
		label: '₴',
	},
	{
		value: 'USD',
		label: '$',
	}
];
const EditCargo: React.FC<Props> = ({currentCargo, handleClose, isOpen, logists}) => {
	const {fetchData, isLoading, isSuccess, error} = useAxios<void, EditCargoReq>(editCargo, null);
	const handledErrors = useHandleError(error);
	const {enqueueSnackbar} = useSnackbar();
	const submitForm = (values: EditCargoForm) => {
		if(currentCargo && values.available_from && values.available_until){

			const data: EditCargoReq['data'] = {
				logist: values.logist,
				address_from: values.address_from,
				address_to: values.address_to,
				trailer_type: values.trailer_type,
				estimated_cost: values.estimated_cost ? +values.estimated_cost : null,
				available_from: dateToDayMonthYearString(new Date(values.available_from.toString())),
				available_until: dateToDayMonthYearString(new Date(values.available_until.toString())),
				weight_volume: values.weight_volume ? +values.weight_volume : null,
				veterinary: values.veterinary,
				adr: values.veterinary,
				comment: values.comment ? values.comment : null,
				vehicle_count: values.vehicle_count ? +values.vehicle_count : null,
				currency: (values.currency && values.estimated_cost) ? values.currency : null,
				is_price_total: values.estimated_cost ? (values.is_price_total === 'true' || values.is_price_total === true) : null,
			};
			fetchData({id: currentCargo.id, data});
		}
	};

	const getInitialValues = (): EditCargoForm => {
		return ({
			logist: currentCargo.logist ?? '',
			address_from: currentCargo.address_from ?? '',
			address_to: currentCargo.address_to ?? '',
			trailer_type: currentCargo.trailer_type ?? TrailerType.tent,
			estimated_cost: currentCargo.estimated_cost ?? '',
			available_from: dayjs(new Date(getIso(currentCargo.available_from))),
			available_until: dayjs(new Date(getIso(currentCargo.available_from))),
			weight_volume: currentCargo.weight_volume ?? '',
			cargo_type: null,
			veterinary: !!currentCargo.veterinary,
			adr: !!currentCargo.adr,
			comment: currentCargo.comment ?? '',
			vehicle_count: currentCargo.vehicle_count ? currentCargo.vehicle_count.toString() : '',
			currency: currentCargo.currency ?? currencies[0].value,
			is_price_total: !!currentCargo.is_price_total,
		});
	};

	const [initialValues, setInitialValues] = useState(getInitialValues());

	useEffect(() => {setInitialValues(getInitialValues);},[currentCargo]);

	useEffect(() => {
		if(isSuccess && !isLoading){
			enqueueSnackbar('Зміни збережено', {variant: 'success'});
			handleClose();
		}
	}, [isLoading]);

	const validationSchema = object({
		logist: string().required('Обов\'язкове поле для вводу'),
		address_from: string()
			.required('Обов\'язкове поле для вводу')
			.max(120, 'Занадто довга адреса'),
		address_to: string()
			.required('Обов\'язкове поле для вводу')
			.max(120, 'Занадто довга адреса'),
		trailer_type: string()
			.required('Обов\'язкове поле для вводу')
			.oneOf(Object.values(TrailerType), 'Невірний тип'),
		estimated_cost: number()
			.typeError('Значення повинно бути числом')
			.positive('Значення повинно бути додатнім'),
		available_from: string().required('Обов\'язкове поле для вводу'),
		available_until: string().required('Обов\'язкове поле для вводу'),
		weight_volume: number()
			.typeError('Значення повинно бути числом')
			.positive('Значення повинно бути додатнім'),
		comment: string().max(17000, 'Занадто довге значення'),
		vehicle_count: number().typeError('Значення повинно бути числом'),
		currency:string()
			.max(3, 'Занадто довге значення')
			.required('Обов\'язкове поле для вводу'),
		is_price_total: boolean(),
		crmId: number()
			.typeError('Значення повинно бути числом')
	});
		
	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 'calc(100vh - 120px)' } }}
			open={isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					submitForm(values);
				}}
				validationSchema={validationSchema}
			>
				{({
					handleChange,
					handleBlur,
					values,
					errors,
					touched,
					setFieldValue,
					setValues,
					handleSubmit,
					setErrors,
					resetForm,
					isValid
				}) => {
					const [isErrorFromServer, setIsErrorFromServer] = useState(false);
					const isErrorLogist = useShowError(errors.logist, touched.logist, isErrorFromServer);
					const isErrorAdressFrom = useShowError(errors.address_from, touched.address_from, isErrorFromServer);
					const isErrorAdressTo = useShowError(errors.address_to, touched.address_to, isErrorFromServer);
					const isErrorTrailerType = useShowError(errors.trailer_type, touched.trailer_type, isErrorFromServer);
					const isErrorEstimatedCost = useShowError(errors.estimated_cost, touched.estimated_cost, isErrorFromServer);
					const isErrorCurrency = useShowError(errors.currency, touched.currency, isErrorFromServer);
					const isErrorIsPriceTotal = useShowError(errors.is_price_total, touched.is_price_total, isErrorFromServer);
					const isErrorAvailableFrom = useShowError(errors.available_from, touched.available_from, isErrorFromServer);
					const isErrorAvailableUntil = useShowError(errors.available_until, touched.available_until, isErrorFromServer);
					const isErrorWeightVolume = useShowError(errors.weight_volume, touched.weight_volume, isErrorFromServer);
					const isErrorComment = useShowError(errors.comment, touched.comment, isErrorFromServer);
					const isErrorVehicleCount = useShowError(errors.vehicle_count, touched.vehicle_count, isErrorFromServer);

					useEffect(() => {
						if(handledErrors){
							setErrors(handledErrors);
						}
					}, [handledErrors]);

					useEffect(() => {
						resetForm();
						setValues(initialValues);
					},[initialValues]);

					useEffect(() => {
						if(isValid && isErrorFromServer){
							setIsErrorFromServer(false);
						}
					},[errors]);

					return (
						<>
							<DialogTitle>Редагування вантажу</DialogTitle>
							<DialogContent>
								<Container sx={{display: 'flex', flexDirection: 'column', gap: '16px', mb: '12px', pt: '6px'}}>
									<FormControl error={isErrorLogist}>
										<InputLabel id="logist-label">Логіст</InputLabel>
										<Select
											required
											labelId="logist-label"
											className="bg-white"
											onBlur={handleBlur('logist')}
											value={values.logist}
											label='Логіст'
											error={isErrorLogist}
											onChange={(event) => setFieldValue('logist', event.target.value)}
										>
											{logists?.map((item) => {
												return <MenuItem
													key={item.login}
													value={item.login}
												>
													{item.name ? item.name : item.login}
												</MenuItem>;
											})}
										</Select>
										{isErrorLogist && <FormHelperText>{errors.logist}</FormHelperText>}
									</FormControl>
									<AutocompleteComponent
										fullAddress={values.address_from}
										setFullAddress={(value) => setFieldValue('address_from', value)}
										label="Місце відправки"
										required
										placeholder="Місто, Країна"
										error={isErrorAdressFrom}
										helperText={isErrorAdressFrom ? errors.address_from : undefined}
									/>
									<AutocompleteComponent
										fullAddress={values.address_to}
										setFullAddress={(value) => setFieldValue('address_to', value)}
										label="Місце доставки"
										required
										placeholder="Місто, Країна"
										error={isErrorAdressTo}
										helperText={isErrorAdressTo ? errors.address_to : undefined}
									/>
									<FormControl error={isErrorTrailerType} fullWidth>
										<InputLabel id='trailer-select-label'>Тип причепа</InputLabel>
										<Select
											labelId='trailer-select-label'
											id='trailer-select'
											value={values.trailer_type}
											label='Тип причепа'
											onChange={(event) => setFieldValue('trailer_type', event.target.value)}
											error={isErrorTrailerType}
										>
											{Object.values(TrailerType).map((value) => (
												<MenuItem key={value} value={value}>{capitalizeFirstLetter(value)}</MenuItem>
											))}
										</Select>
										{(isErrorTrailerType && errors.trailer_type) && <FormHelperText>{errors.trailer_type}</FormHelperText>}
									</FormControl>
									<FormControl error={isErrorIsPriceTotal}>
										<Box sx={{display: 'flex', flexDirection: 'row'}}>
											<TextField
												value={values.estimated_cost}
												onChange={handleChange('estimated_cost')}
												onBlur={handleBlur('estimated_cost')}
												error={isErrorEstimatedCost}
												label='Oрієнтовна ціна'
												fullWidth
												helperText={isErrorEstimatedCost ? errors.estimated_cost : undefined}
											/>
											<TextField
												select
												value={values.currency}
												onChange={(event) => setFieldValue('currency', event.target.value)}
												helperText={isErrorCurrency ? errors.currency : undefined}
												sx={{width: '65px', ml: '6px'}}
											>
												{currencies.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</Box>
										<RadioGroup
											sx={{ml: '12px'}}
											row
											value={values.is_price_total}
											onChange={handleChange('is_price_total')}
										>
											<FormControlLabel value={true} control={<Radio />} label='Повна вартість'/>
											<FormControlLabel value={false} control={<Radio />} label='Ціна за тонну'/>
										</RadioGroup>
										{(isErrorIsPriceTotal && errors.is_price_total) && <FormHelperText>
											{errors.is_price_total}
										</FormHelperText>}
									</FormControl>
									<FormControl error={isErrorAvailableFrom}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
											<DatePicker
												label="Доступний з"
												value={values.available_from}
												onChange={(value) => setFieldValue('available_from', value)}
												maxDate={values.available_until ?? undefined}
											/>
										</LocalizationProvider>
										{(isErrorAvailableFrom && errors.available_from) && <FormHelperText>
											{errors.available_from}
										</FormHelperText>}
									</FormControl>
									<FormControl error={isErrorAvailableUntil}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
											<DatePicker
												label="Доступний по"
												value={values.available_until}
												onChange={(value) => setFieldValue('available_until', value)}
												minDate={values.available_from ?? undefined}
											/>
										</LocalizationProvider>
										{(isErrorAvailableUntil && errors.available_until) && <FormHelperText>
											{errors.available_until}
										</FormHelperText>}
									</FormControl>
									<TextField
										value={values.weight_volume}
										onChange={handleChange('weight_volume')}
										onBlur={handleBlur('weight_volume')}
										error={isErrorWeightVolume}
										label='Вага в тоннах'
										helperText={isErrorWeightVolume ? errors.weight_volume : undefined}
									/>
									<TextField
										value={values.vehicle_count}
										onChange={handleChange('vehicle_count')}
										onBlur={handleBlur('vehicle_count')}
										error={isErrorVehicleCount}
										label='Кількість транспортних засобів'
										helperText={isErrorVehicleCount ? errors.vehicle_count : undefined}
									/>
									<TextField
										value={values.comment}
										onChange={handleChange('comment')}
										onBlur={handleBlur('comment')}
										error={isErrorComment}
										label='Коментар'
										helperText={isErrorComment ? errors.comment : undefined}
									/>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													defaultChecked={values.veterinary}
													onChange={(event, checked) => setFieldValue('veterinary', checked)}
												/>
											}
											label="Потрібні ветеринарні документи"
										/>
										<FormControlLabel
											control={
												<Checkbox
													onChange={(event, checked) => setFieldValue('adr', checked)}
													defaultChecked={values.adr}
												/>
											}
											label="ADR"
										/>
									</FormControl>
								</Container>
							</DialogContent>
							<DialogActions>
								<Button autoFocus onClick={handleClose}>
									Відміна
								</Button>
								<LoadingButton loading={isLoading} onClick={() => handleSubmit()}>Зберегти</LoadingButton>
							</DialogActions>
						</>
					);}}
			</Formik>
		</Dialog>
	);
};

export default EditCargo;