import React from 'react';
import {Box, Typography} from '@mui/material';

interface Props {
	label: string
	value: string | number | null | undefined
}
const none = 'Данні відсутні';
const Row: React.FC<Props> = ({label, value}) => {
	return (
		<Box sx={{display: 'flex', alignItems: 'center', mb: '6px'}}>
			<Typography variant="button" mr='6px'>
				{label}
			</Typography>
			<Typography variant='body2' >
				{value ?? none}
			</Typography>
		</Box>
	);
};

export default Row;