import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {AuthContext} from '../components/app/App';

const AuthProtected = () => {
	const { isAuth } = useContext(AuthContext);
	
	if (!isAuth) {
		return (
			<Navigate to={{ pathname: '/login' }} />
		);
	}
	
	return (
		<React.Fragment>
			<Outlet/>
		</React.Fragment>
	);
};

export default AuthProtected;