import React, {useEffect, useRef, useState} from 'react';
import {Alert, Avatar, CircularProgress, List, ListItemAvatar, ListItemButton, ListItemText} from '@mui/material';
import {ApiResponse} from '../../hook/useAxios';
import {ErrorLog} from '../types';
import {List as ListType} from '../types/response';
import {isoToString} from '../../services/TimeService';
import Loader from '../ui/Loader';
import {getLogistName} from '../../services/getLogistName';

interface Props {
	setCurrentLog: (log: ErrorLog | null) => void
	currentLog: ErrorLog | null
	apiResponse: ApiResponse<ListType<ErrorLog>, number>
	currentPage: number
	setCurrentPage: (page: number) => void
}

const ParsingErrorsList: React.FC<Props> = ({setCurrentLog, currentLog, apiResponse, currentPage, setCurrentPage}) => {
	const [currentList, setCurrentList] = useState<ErrorLog[]>([]);
	const {isError, isLoading, isSuccess, data} = apiResponse;
	const containerRef = useRef<HTMLUListElement>(null);
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	
	useEffect(() => {
		if(isSuccess && !isLoading && data){
			if(currentPage === 1){
				setCurrentList(data.data);
			} else {
				setCurrentList(prevList => [...prevList, ...data.data]);
			}
		}
		
		if(isSuccess && !currentLog && data && data.data.length > 0 ){
			setCurrentLog(data.data[0]);
		}
	},[isSuccess, isLoading]);

	useEffect(() => {
		const handleScroll = () => {
			const container = containerRef.current;
			if (container) {
				const { scrollTop, scrollHeight, clientHeight } = container;
				if (data && !isLoading && data.meta.current_page < data.meta.last_page && scrollTop + clientHeight >= scrollHeight - 100) {
					setCurrentPage(data.meta.current_page + 1);
				}
			}
		};

		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [data, isLoading]);
	
	return (
		<React.Fragment>
			{
				<List
					sx={{
						width: '100%',
						maxWidth: 360,
						overflow: 'auto',
						position: 'relative',
						height: '100%',
						maxHeight: '1320px'
					}}
					ref={containerRef}
				>
					{(isSuccess && currentList.length > 0) ? currentList.map((item) => (
						<ListItemButton
							key={item.id}
							selected={currentLog?.id === item.id}
							onClick={() => setCurrentLog(item)}
						>
							<ListItemAvatar>
								<Avatar>
									{item.id}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={isoToString(item.created_at)}
								secondary={getLogistName(logistsList, item.payload?.logist ?? '')}
							/>
						</ListItemButton>
					)) : isError ? <Alert severity="error">Помилка завантаження, спробуйте пізніше</Alert> : <Loader/>}
					{(isSuccess && isLoading) &&
						<ListItemButton sx={{display: 'flex', width: '100%', height: '72px', justifyContent: 'center', alignItems: 'center'}}>
							<CircularProgress />
						</ListItemButton>
					}
				</List>}
		</React.Fragment>
	);
};

export default ParsingErrorsList;