import React, {useState} from 'react';
import {Box, FormControl, ListItemText, OutlinedInput} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {Grouping, ReportReq} from '../types';
import {DatePickerComponent} from '../../components/date-range-picker/DatePickerComponent';
import dayjs from 'dayjs';
import {Logist} from '../../types';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import {dateToDayMonthYearString} from '../../services/TimeService';
import {getLogistName} from '../../services/getLogistName';

interface Props {
	logistList: Logist[],
	isLoading: boolean,
	fetch: (val?: ReportReq) => void
}

const FiltersBar: React.FC<Props> = ({
	logistList,
	fetch,
	isLoading
}) => {
	const now = Date.now();
	const [groupingBy, setGroupingBy] = useState<Grouping>(Grouping.logist);
	const [dateStart, setDateStart] = useState(dayjs(new Date(now - 604800000)));
	const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
	const [logists, setLogists] = useState<string[]>([]);
	const jsonLogistsList = localStorage.getItem('logists');
	const logistsList: {[login: string]: string} = jsonLogistsList ? JSON.parse(jsonLogistsList) : {};
	const getData = () => {
		const req: ReportReq = {
			group_by: groupingBy,
			filters: {
				logists,
				created_at:{
					from: dateToDayMonthYearString(new Date(dateStart.toString())),
					to: dateToDayMonthYearString(new Date(dateEnd.toString()))
				}
			}
		};
		fetch(req);
	};

	return (
		<Box sx={{display: 'flex', gap: '6px', alignItems: 'center', justifyContent: 'space-between', mt: '32px', mb: '12px'}}>
			<FormControl sx={{width: '160px'}}>
				<InputLabel id='by-group-select-label'>Сортування за</InputLabel>
				<Select
					labelId='by-group-select-labe'
					value={groupingBy}
					label='Сортування за'
					onChange={(event) => setGroupingBy(event.target.value as Grouping)}
				>
					<MenuItem value={Grouping.date}>
						Датою
					</MenuItem>
					<MenuItem value={Grouping.logist}>
						Логістами
					</MenuItem>
				</Select>
			</FormControl>
			<Box sx={{width: '160px'}}>
				<DatePickerComponent
					label="Дата початок"
					date={dateStart}
					setDate={setDateStart}
					maxDate={dateEnd}
				/>
			</Box>
			<Box sx={{width: '160px'}}>
				<DatePickerComponent
					label="Дата кінець"
					date={dateEnd}
					setDate={setDateEnd}
					minDate={dateStart}
				/>
			</Box>
			<FormControl sx={{width: '160px'}}>
				<InputLabel id="logists-select-label">Логісти</InputLabel>
				<Select
					labelId="logists-select-label"
					multiple
					value={logists}
					onChange={(event) => setLogists(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
					input={<OutlinedInput label="Логісти" />}
					renderValue={(selected) => selected.map(login => getLogistName(logistsList, login)).join(', ')}
				>
					{logistList.map((logist, index) => (
						<MenuItem key={index} value={logist.login}>
							<Checkbox checked={logists.indexOf(logist.login) > -1} />
							<ListItemText primary={logist.name ? logist.name : logist.login} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<LoadingButton
				loading={isLoading}
				onClick={getData}
			>
				Завантажити
			</LoadingButton>
		</Box>
	);
};

export default FiltersBar;