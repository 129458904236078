import React from 'react';
import {CircularProgress, Container} from '@mui/material';

const Loader = () => {
	return (
		<Container sx={{display: 'flex', width: '100%', height: '300px', justifyContent: 'center', alignItems: 'center'}}>
			<CircularProgress />
		</Container>
	);
};

export default Loader;