import React from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/uk';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface DatePickerComponentProps {
  label: string;
  date:  dayjs.Dayjs;
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
	maxDate?: dayjs.Dayjs
	minDate?: dayjs.Dayjs
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
	label,
	date,
	setDate,
	minDate,
	maxDate
}) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
			<DatePicker
				label={label}
				value={date}
				onChange={(newValue) => {
					if(newValue){
						setDate(newValue);
					}
				}}
				className="full-width"
				minDate={minDate}
				maxDate={maxDate}
			/>
		</LocalizationProvider>
	);
};
