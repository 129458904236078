import React from 'react';
import {ICargo} from '../../types';
import CargoMarker from './CargoMarker';
import {Clusterer} from '@react-google-maps/marker-clusterer';
interface Props {
	cargos: ICargo[]
	marker: string | null,
	setMarker: (value: string | null) => void,
	clusterer?: Clusterer,
}

const CargoMarkerList: React.FC<Props> = ({cargos, marker, setMarker, clusterer}) => {
	const groupCargoMarkersByPosition = (markers: ICargo[]): ICargo[][] => {
		const groupedMarkers: { [key: string]: ICargo[] } = {};

		markers.forEach(marker => {
			const key = `${marker.loading_point_lat}-${marker.loading_point_lon}`;
			if (!groupedMarkers[key]) {
				groupedMarkers[key] = [];
			}
			groupedMarkers[key].push(marker);
		});

		return Object.values(groupedMarkers);
	};

	const distributeMarkersInSemiCircle = (markers: ICargo[]): ICargo[] => {
		const radius = 0.1;
		const angleStep = -Math.PI / (markers.length - 1);
		const startAngle = Math.PI;

		return markers.map((marker, index) => {
			const angle = startAngle + index * angleStep;
			const offsetLat = radius * Math.cos(angle);
			const offsetLng = radius * Math.sin(angle)*1.5;

			return {
				...marker,
				loading_point_lat: `${+marker.loading_point_lat + offsetLat}`,
				loading_point_lon: `${+marker.loading_point_lon + offsetLng}`,
			};
		});
	};

	return (
		<React.Fragment>
			{groupCargoMarkersByPosition(cargos).flatMap((group) => {
				if (group.length > 1) {
					const distributedMarkers = distributeMarkersInSemiCircle(group);
					return distributedMarkers.map(cargo => (
						<CargoMarker key={cargo.id} cargo={cargo} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
					));
				} else {
					return (
						<CargoMarker key={group[0].id} cargo={group[0]} marker={marker} setMarker={setMarker} clusterer={clusterer}/>
					);
				}
			})}
		</React.Fragment>
	);
};

export default CargoMarkerList;