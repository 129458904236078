import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

export const PortalComponent:React.FC<{children?: React.ReactNode | undefined}> = ({children}) => {
	const [container] = useState(() => document.createElement('div'));

	useEffect(() => {
		document.body.appendChild(container);
		return () => {
			document.body.removeChild(container);
		};
	}, []);

	return ReactDOM.createPortal(children, container);
};